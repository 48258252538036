window.EventsTrackersListView = Backbone.View.extend({

    tagName: 'div',
    className: 'panel panel-default',
    template: JST["events-trackers-list"],
  event_data:[],
  event_id:0,
    events: {
        'click .trackers-refresh': 'refresh',
        'click #btn-modal-assign-wizard': 'modal_assign_wizard',
        'click .command-submit': 'trackersSubmitCommand',
        'click #trackers-check-all': 'trackers_check_all',
        'click #btn-trackers-returns': 'trackers_returns_wizard',
        'click #btn-trackers-list': 'trackers_list_html',
        'click #btn-trackers-csv': 'download_csv',
        'click #btn-trackers-control': 'trackers_control'
    },

    initialize: function(options) {
    
      /*  _(this).bindAll('add', 'remove');
        this.collection.bind('add', this.add);
        this.collection.bind('remove', this.remove);
*/
        this.listenTo(this.collection, 'add', this.add);
        this.listenTo(this.collection, 'remove', this.remove);
        this.event_id = options.event_id ||  false;
        this._event_id = options._event_id ||  false;
    },

    add: function(tracker) { // Añadido nuevo elemento a la coleccion
        $(this.el).find('table tbody').append(new EventsTrackersListItemView({
            model: tracker
        }).render().el);

        var validLocations = ['ceei', 'out'];
        var location = tracker.get('location') || 'otros';
        if (validLocations.indexOf(location) === -1) {
            location = 'otros';
        }

        var current = parseInt($('#number-trackers-' + location).data('value'), 10);
        var newValue = current +1;
        $('#number-trackers-' + location).html(newValue);
        $('#number-trackers-' + location).data('value', newValue);
        //A REVISAR
      //  $('#number-trackers-text').html(this.collection.length);
    },

    remove: function() {
      // A REVISAR
      //  $('#number-trackers-text').html(this.collection.length);
    },

    refresh: function() {
        // TODO meter un cargando o similar hasta el evento SYNC
        this.collection.fetch();
    },
    modal_assign_wizard: function() {
        var assignWizard = new TrackerAssignWizard({_event:this.event_data._id});
        $('body').append(assignWizard.render().el);
        assignWizard.$el.modal('show');
        assignWizard.$el.on('shown.bs.modal', function() {
            assignWizard.$el.find("#last-added").addClass('hide');
            $('#users-typeahead').focus();
        });
        assignWizard.$el.on('hidden.bs.modal', function() {
   
            assignWizard.$el.remove();
            assignWizard.remove();
        });
    },
    render: function(event_data) {
    
        var that = this;
        this.event_data = event_data;

        this.event_id=event_data.event_id;
 
      //  this.$el.html(Handlebars.compile(JST["events/trackers-list.hbs"]()));
    this.$el.html(this.template());
        var afterAdd = function() {
            $('#trackers-typeahead').val('');
            notify.success('tracker agregado', true);
        };

        this.typeahead = this.$('.typeahead')
            .keypress(function(event) {
                var keycode = (event.keyCode ? event.keyCode : event.which);
                if (keycode === 13) {
                    event.preventDefault();
                  
                    var datum = $('input.typeahead').data().ttView.dropdownView.getFirstSuggestion().datum;
                    that.collection.create(datum);
                    that.$('.typeahead').typeahead('setQuery', '');
                    afterAdd();
                }
            })
            .typeahead('destroy') // prefetch!
        .typeahead({
            prefetch: '/api/trackers/free',
            ttl: 1,
            valueKey: 'name',
            limit: 15
        }).on('typeahead:selected', function(e, datum) {
            var tracker = that.collection.get(datum._id);
            if (tracker) {
                notify.error('Este tracker ya está añadido', true);
                return;
            }

          
            that.collection.create(datum);
            afterAdd();
        });

        return this;
    },









    trackers_get_selected: function() {
        var trackers = [];

        $('#table-trackers2 tr').each(function() {
            /*
         if ($(this).find('.trackers-check-all').is(':checked')) {
               var user = $(this);
                trackers.push(user);
           }
                */
      
        });

        const tabla = document.getElementById("table-trackers2");
        // Seleccionamos todas las filas del cuerpo de la tabla
        const filas = tabla.querySelectorAll("tbody tr");
        const resultado = [];
        let hayCheckboxMarcado = false;
      
        // Recorremos cada fila
        filas.forEach(fila => {
          // Obtenemos el checkbox de la primera columna (celda 0)
          const checkbox = fila.cells[0].querySelector('input[type="checkbox"]');
          // Verificamos que el checkbox exista y esté marcado
          if (checkbox && checkbox.checked) {
            hayCheckboxMarcado = true;
            // Obtenemos el contenido HTML de la segunda columna (celda 1)
            const contenido = fila.cells[1].innerHTML;
            // Separamos el contenido por los saltos de línea (<br>)
            const lineas = contenido.split(/<br\s*\/?>/);
            // Si existe al menos una segunda línea, la agregamos al array
            if (lineas.length >= 2) {
              resultado.push(lineas[2].trim());
            }
          }
        });

        return resultado;
    },

    trackers_check_all: function() {
        if ($('#trackers-check-all').prop('checked')) {
            $('#table-trackers2 tr').find('.checkbox').each(function() {
                $(this).prop('checked', true);
            });
        } else {
            $('#table-trackers2 tr').find('.checkbox').each(function() {
                $(this).prop('checked', false);
            });
        }
    },

    trackers_returns_wizard: function() {
        var trackersReturnsC = new TrackersReturnsCollection();
        var trackersReturnsV = new TrackerReturnsView({collection: trackersReturnsC});
        $('body').append(trackersReturnsV.render().el);
        trackersReturnsV.$el.modal('show');
    },

    trackers_list_html: function() {
        window.open("/api/events/"+this.collection._event+"/users.html");
    },

    download_csv: function() {
        window.open("/api/events/"+this.collection._event+"/users.csv?download=1");
    },

    trackers_control: function() {
        window.open("/api/events/"+this.collection._event+"/users-trackers-control.html");
    },

    trackersSubmitCommand: function() {

   let trackers=this.trackers_get_selected()// para obtener los seleccionados
   
        var self = this;
        var url = this.collection.url + "/commands";

        var data = {
            alias: $('#command-action').val(),
            trackers:trackers,
            type: $('#command-type').val(),
            data: $('#command-payload').val()
        };

        if (data.alias == "-1") {
            alert('Tienes que seleccionar algo');
            return;
        } else if (data.alias == "raw") {
            data.alias = "";
            if (data.type == "") {
                alert("Es necesario el RAW");
                return;
            }
        } else if (data.alias == "emptygprs") {
            if (!confirm("Estás seguro???")) {
                return;
            }
        } else if (data.alias == "clearqueue") {
            if (!confirm("Estás seguro de querer vaciar la cola de programación para todos los terminales?")) {
                return;
            }
            url += "/clearqueue";
        }

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                command: data
            },
            success: function() {
                notify.success('La acción se ha ejecutado', true);
                // AQUI HAY QUE HACER UN RELOAD DE LA COLECCION
                self.collection.fetch();
            },
            error: function() {
                notify.error('Error al ejecutar la acción', true);
            }
        });
    }
});
