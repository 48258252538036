window.EventsMediaForm = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade edit-user',
    template: JST["events/events-media-form.hbs"],
    previusAttributes: {},

    events: {
        'click #media-save': 'save',
        'click #media-cancel': 'cancel',
        'change #media-type': 'changeMediaType'
    },

    initialize: function(options) {
        if (!this.model) {
            this.model = new MediaModel();
        }
        this.previusAttributes = this.model.toJSON();
        this.event_id = options.event_id;
    },


    render: function() {
        this.$el.html(this.template());
        this.$el.modal({
            keyboard: false,
            backdrop: 'static'
        });
        return this;
    },

    save: function() {
        var that = this;

        var success = {
            success: function() {
                notify.success('Objeto de mediabar guardado');
                that.close();
            }
        };

        var type = $('#media-type').val();
        this.model.set('type', type);
        this.model.set('latlng', [$('#media-lat').val(), $('#media-lnt').val()]);

        // si no es foto guardamos ya.
        if (type !== 'photo') {
            this.model.set('content', $('#media-content-' + type).val());
            return this.model.save(null, success);
        }

        // si es una foto comprobamos que sea valida y cargamos
        var image = this.$el.find('#media-content-photo').val();
        if (!image.length) {
            return notify.error('No has seleccionado ninguna imagen para cargar.', true);
        }

        var url = ['/api/events', this.event_id, 'media'].join('/');
        $('#media-form').ajaxSubmit({
            type: 'post',
            url: url,
            success: function(data) {
                model.set('content', data.permalink);
                return this.model.save(null, success);
            }
        });

    },

    close: function() {
        var that = this;
        this.$el.modal('hide').on('hidden.bs.modal', function() {
            that.remove();
            that.unbind();
        });
    },

    cancel: function() {
        var that = this;
        if (this.model.changedAttributes()) {
            bootbox.confirm('Han habido cambios, salir sin guardar?', function(result) {
                if (result) {
                    if (that.model.isNew()) {
                        this.model.set(that.previusAttributes);
                    }
                }
            });
        }

        if (this.model.isNew()) {
            this.model.destroy();
        }

        this.close();
    },

    changeMediaType: function(e) {
        var $el = $(e.currentTarget);
        var media = $el.val();

        $('.media-type').addClass('hide');
        $('#media-' + media).removeClass('hide');

        this.model.set('type', media);
    },
});
