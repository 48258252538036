window.EventsCommentsListItemView = Backbone.View.extend({

    tagName: 'tr',
    template: JST['events/events-comments-list-item.hbs'],

    events: {
        "click .btn-remove": "doRemove"
    },

    initialize: function(options) {
        this.users = options.users;
    },

    // wrapper para doRemove. Habilita la función ctrl+click
    doRemove: function(e) {
        var that = this;
        this.model.url = '/api/comments/' + this.model.get('_id');
        function remove() {
            that.model.destroy({
                success: function() {
                    notify.success('Eliminando!', true);
                    that.undelegateEvents();
                    that.$el.removeData().unbind().remove();
                },
                error: function() {
                    notify.success('Error! Inténtalo de nuevo.', true);
                }
            });
        }

        if (e.ctrlKey) {
            remove();
            return;
        }

        bootbox.confirm('Seguro que deseas eliminar este comentario?', function(eliminar) {
            if (eliminar) {
                remove();
            }
        });
    },

    render:function () {
        var data = this.model.toJSON();
        data.published = tmw.dateFormatted(data.published);
        this.$el.html(this.template(data));
        return this;
    }
});
