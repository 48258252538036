Handlebars.registerHelper('equals', function(lvalue, rvalue, options) {
    if (arguments.length < 3) {
        throw new Error("Handlebars Helper equal needs 2 parameters");
    }
    if (lvalue !== rvalue) {
        return options.inverse(this);
    } else {
        return options.fn(this);
    }
});

Handlebars.registerHelper('notEmpty', function(item, block) {
  return (item && item.replace(/\s/g,"").length) ? block.fn(this) : block.inverse(this);
});

/**
 * include JST templates.
 * Ojo, data debe ser un array de objetos.
 */
Handlebars.registerHelper('include', function(tplname, data) {
    var result = '';
    data = data || {};

    if (!data.length) {
        // si data está vacío hacemos una llamada al template,
        // el cual querrá gestionar estos datos vacíos para indicar
        // "no hay resultados" o cualquier cosa de esas...
   
      //  var tpl = Handlebars.compile(JST[tplname](data));
          var tpl = JST[tplname](data);
   
        return tpl;
    } else {
       _.each(data, function(obj) {
//var tpl = Handlebars.compile(JST[tplname](obj));
              var tpl = JST[tplname](obj);
            result = result + tpl;

        });
    }
    return result;
});

Handlebars.registerHelper('raw', function() {
    return JSON.stringify(this);
});

// set select option
Handlebars.registerHelper('select', function( value, options ){
    var $el = $('<select />').html( options.fn(this) );
    $el.find('[value=' + value + ']').attr({'selected':'selected'});
    return $el.html();
});

Handlebars.registerHelper('multiString', function(string, number) {
    return string.repeat(number);
});

Handlebars.registerHelper('dateToDMY', function(number) {
    return traky.dateFormatted(number);
});

Handlebars.registerHelper('checklist', function(list, selected) {

    var result = '';

    var i;
    for (i in list) {
        var is_selected = 'glyphicon-unchecked';
        if (i == selected) {
            is_selected = 'glyphicon-check checked';
        }

        result += '<li class="list-group-item">';
        result += '<a href="#" class="set-answer" data-index="'+i+'"><span class="glyphicon '+is_selected+'"></span>';
        result += '<span class="answer-title">'+list[i]+'</span></a>';
        result += '<a href="javascript:void();" data-index="'+i+'" class="deleteAnswer glyphicon glyphicon-remove-circle"></a>';
        result += '</li>';
        result += '';
    }

    return result;
});

// Access object value with a variable key
Handlebars.registerHelper('withItem', function(object, options) {
    if (!object.length) {
        return '';
    }

    return options.fn(object[options.hash.key]);
});

// {{checkArray VALUES 'VALUE'}}
// donde VALEUS es un array de strings y VALUE un string que debe existir dentro de VALUES
Handlebars.registerHelper('checkboxArray', function(values, checked) {
    if (typeof values !== 'object') values = [values];
    if (values.indexOf(checked) >= 0) {
        return 'checked="checked"';
    }
});

// @see checkArray helper
Handlebars.registerHelper('selectArray', function(values, selected) {
    if (typeof values !== 'object') values = [values];
    if (values.indexOf(selected) >= 0) {
        return 'selected="selected"';
    }
});
