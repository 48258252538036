window.EventsFilesListView = Backbone.View.extend({

    tagName: 'div',
    className: 'panel panel-default',
    template: JST["events-files-list"],

    events: {
        'click #file-add': 'addFile'
    },

    initialize: function() {
      /*  _(this).bindAll('add');
        this.collection.bind('add', this.add);
        */
          this.listenTo(this.collection, 'add', this.add);
    },

    add: function(file) {
        var render = new EventsFilesListItemView({model:file}).render().el;
        $(this.el).find('table tbody').append(render);
    },

    addFile: function() {
        var file = new EventFile();
        this.collection.add(file);
        new EventsFileForm({model:file}).render();
    },

    render: function() {
        this.$el.html(this.template());
        return this;
    }
});
