window.EventGroupsCollection = Backbone.Collection.extend({
    model: EventGroup,
    initialize: function(options) {
        options = (options || {});
        if (options.event_id) {
            this.setUrl(options.event_id);
        } else {
            this.url = '/api/groups'; // Error 404??
        }
    },

    setUrl: function(event_id) {
        this.url = "/api/events/" + event_id + "/groups";
    }
});
