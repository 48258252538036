window.EventsTrackersListItemView = Backbone.View.extend({

    tagName: 'tr',
  template: JST["events-trackers-list-item"],
    events: {
        "click .deleteTracker": "deleteItem"
    },

    initialize: function() {
        this.model.bind('change', this.change, this);
    },

    deleteItem: function(event) {
        event.preventDefault();
        var that = this;

        bootbox.confirm("Seguro que deseas desasociar el tracker del evento?", function(result) {
            if(result) {
                that.model.destroy();
                that.$el.fadeIn('fast', function() {
                    that.remove();
                });
            }
        });
    },

    change: function() {
        this.render();
    },

    render:function () {
        var data = this.model.toJSON();
        data.elapsed_time_gps = "-";
        data.elapsed_time_server = "-";
        data.battery100 = "-";

        if(data.latest_position.gps_timestamp) {
            data.elapsed_time_gps = time_ago(data.latest_position.gps_timestamp/1000,2);
        }

        if(data.latest_position.server_timestamp) {
            data.elapsed_time_server = time_ago(data.latest_position.server_timestamp/1000,2);
        }

        if(data.latest_position.battery) {
            // data.battery100 = ((data.latest_position.battery * 100) / 4.2).toFixed(2); // BURROOO
            if(data.latest_position.battery>=4.2) data.latest_position.battery = 4.2;
            else if(data.latest_position.battery<=3.5) data.latest_position.battery = 3.5;
            data.latest_position.battery -= 3.5;
            data.battery100 = ((data.latest_position.battery * 100) / 0.7).toFixed(2);
        }

        if(data.latestCommand) {
            data.latestCommand.elapsed_time = time_ago(data.latestCommand.release/1000,2);
        }

        if(data.metadata && data.metadata.settings) {
            data.metadata.firmware += " | " + data.metadata.settings;
        }

    //    this.$el.html(Handlebars.compile(JST["events/trackers-list-item.hbs"](data)));
      this.$el.html(this.template(data));
        return this;
    }
});
