/**
 * @TODO agregar ruta al index (una vez haya algo que meter en el index)
 */
var Route = Backbone.Router.extend({

    routes: {
        '': 'home',


            "admin/trackers": "trackers_list",
            "admin/trackers/add": "addTracker",
            "admin/trackers/edit/:id": "editTracker",
            "admin/events": "events_list",
            "admin/events/add": "addEvent",
            "admin/events/edit/:id": "editEvent",

            "admin/tracks": "tracks_list",
            "admin/tracks/add": "addTrack",
            "admin/tracks/edit/:id": "editTrack",


        "admin": "admin"
    },

    admin: function () {
          //  var mainAdmin = new MainAdminView();
          //  $('#main').html(mainAdmin.render().el);
        },
    home: function() {
    
    //  app.navigate('admin/users', true);
    },

    initialize: function() {
       

    },

    // -------------------------------------------------------------------------
       // Trackers


       trackers_list: function () {

           this.trackersList = new TrackersCollection();
           this.TrackersListView = new TrackersListView({
               collection: this.trackersList
           });
          
           this.trackersList.fetch({
               success: function (model) {
               
                   traky.trackers.dataTable('trackers-datatable');
                   $('#number-trackers-text').text($('#trackers-datatable_info').text());
                   //$('#number-trackers').text(model.length);
               }
           });
           
           $('#main').html(this.TrackersListView.render().el);
           traky.setHGroup('Trackers');
           adminMenuControl();
       },

       addTracker: function () {
       
          var that = this;
          this.trackersList = new TrackersCollection();
           var trackersForm = new TrackersForm();
           $('#main').html(trackersForm.render().el);
           traky.setHGroup('Añadir tracker');
           traky.trackers.init();
           adminMenuControl();
           //alert('error mortal! (404)');
       },

       editTracker: function (id) {
        
           var that = this;
           this.trackersList = new TrackersCollection();
           this.trackersList.fetch({
               data: {
                   _id: id
               },
               success: function () {
                   var tracker = that.trackersList.get(id);

                   // Obtener el queue
                   
                   // trackermcommans es la coleccion
            /*       var commands = new TrackerCommands({
                       _tracker: id
                   });
                   commands.fetch();
               
                   var commandsView = new TrackerCommandsListView({
                       collection: commands
                   });
*/
                   that.TrackersForm = new TrackersForm({
                       model: tracker,
                    //   commandsView: commandsView
                   });

                   $('#main').html(that.TrackersForm.render(tracker.toJSON()).el);
                   traky.setHGroup('Editar tracker');
                  traky.trackers.init(tracker.attributes);
                   traky.trackers.form();
                   $('#tracker-modal').on('shown.bs.modal', function () {
                       that.TrackersForm.notes_setup();
                   });
                   that.TrackersForm.notes_generate();
                   adminMenuControl();
               }
           });
       },
/***********************************************************************************************++*/
/******eventos***/
events_list: function () {

  this.eventsList = new EventsCollection();
    this.EventsListView = new EventsListView({
        collection: this.eventsList
    });
    this.eventsList.fetch({
        success: function () {
            traky.events.dataTable('events-datatable');
            $('#number-events-text').text($('#events-datatable_info').text());
        }
    });
    $('#main').html(this.EventsListView.render().el);
    traky.setHGroup('Eventos');
    adminMenuControl();
},


editEvent: function (id) {

 
   var that = this;
   this.events = new EventsCollection();
   this.events.fetch({
       data: {
           _id: id
       },
       success: function () {
           var modelEvents = that.events.get(id);
           // FALTA OBTENER LOS TRACKERS DEL Event
           // FALTA LOS USUARIOS DEL Evento
           // SESIONES ...
           
      
           app.eventTrackersCollection = new EventTrackersCollection({event_id: id});
               var trackersView = new EventsTrackersListView({
                   collection: app.eventTrackersCollection
               });
               app.eventTrackersCollection.fetch({
                   success: function() {
                  
                       trackersView.$el.find('.table').dataTable({
                           "paging": true,
                           "sPaginationType": "full_numbers",
                           aLengthMenu: [
                               [10, 25, 50, 100, 200, -1],
                               [10, 25, 50, 100, 200, "All"]
                           ]
                       });

                   }
               });

               app.eventUsersCollection = new EventUsersCollection({event_id: id});

           
               var usersView = new EventsUsersListView({
                   model:EventUser,
                   collection: app.eventUsersCollection,
                //   event_id:this.model.get(event_id) , // id en wordpress (6 cifras)

                   _event_id: id
               });

               app.eventUsersCollection.fetch({
                   success: function() {
                   
                       usersView.$el.find('#table-users').dataTable({
                           "paging": true,
                           "sPaginationType": "full_numbers",
                           aLengthMenu: [
                               [10, 25, 50, 100, 200, -1],
                               [10, 25, 50, 100, 200, "All"]
                           ]
                       });
                   }
               });
           var sessions = new EventSessionsCollection({event_id: id});
               var sessionsView = new EventsSessionsListView({
                   collection: sessions
//                   model: model
               });
               sessions.fetch();

               var groups = new EventGroupsCollection({event_id: id});
               var groupsView = new EventsGroupsListView({collection: groups});
               groups.fetch();

              var sponsors = new EventSponsorsCollection({event_id: id});
               var sponsorsView = new EventsSponsorsListView({collection: sponsors});
               sponsors.fetch();

          /*     var comments = new EventCommentsCollection({event_id: id});
                            var commentsView = new EventsCommentsListView({
                                collection: comments,
                                users: app.eventUsersCollection
                            });
                            comments.fetch();
*/
                            var media = new EventMediaCollection({event_id: id});
                            var mediaView = new EventsMediaListView({
                                collection: media,
                                event_id: id
                            });
                            media.fetch();

                            var files = new EventFilesCollection({event_id: id});
                            var filesView = new EventsFilesListView({collection: files});
                            files.fetch();
               /////////////

           that.EventsForm = new EventsForm({
               model:modelEvents ,
               trackersView: trackersView,
                   usersView: usersView,
                   groupsView: groupsView,
                   sponsorsView: sponsorsView,
                   sessionsView: sessionsView,
                   mediaView: mediaView,
                   filesView: filesView
  //                 commentsView: commentsView

           });

           $('#main').html(that.EventsForm.render(modelEvents.toJSON()).el);
           traky.setHGroup('Editar evento');
          traky.events.init(modelEvents.attributes);
           adminMenuControl();
       }
   });
   // users del evento
   app.eventUsersCollection = new EventUsersCollection({event_id: id});
                  var usersView = new EventsUsersListView({
                      collection: app.eventUsersCollection,
                      event_id: that.events.get(id), // id en wordpress (6 cifras)
                      _event_id: id
                  });
                  app.eventUsersCollection.fetch({
                      success: function() {
                          usersView.$el.find('#table-users').dataTable({
                              "paging": true,
                              "sPaginationType": "full_numbers",
                              aLengthMenu: [
                                  [10, 25, 50, 100, 200, -1],
                                  [10, 25, 50, 100, 200, "All"]
                              ]
                          });
                      }
                  });


},
addEvent: function () {

   var that = this;
   this.eventsList = new EventsCollection();
    var eventsForm = new EventsForm();

    $('#main').html(eventsForm.render().el);
    traky.setHGroup('Añadir evento');
    traky.events.init();
    adminMenuControl();
    //alert('error mortal! (404)');
},

// -------------------------------------------------------------------------
    // Tracks
    tracks_list: function () {
 var that = this;
       

        this.tracksList = new TracksCollection();
        this.TracksListView = new TracksListView({
            collection: this.tracksList
        });
       
        this.tracksList.fetch({
            success: function (model) {
         
              traky.tracks.dataTable('table-tracks');



            }
        });
       
        $('#main').html(this.TracksListView.render().el);

        traky.setHGroup('Tracks');
        adminMenuControl();

    },

    addTrack: function () {
        this.trackForm = new TrackForm();
        $('#main').html(this.trackForm.render().el);
        //this.trackForm.start();
    },

    editTrack: function (id) {
        var that = this;
        // Burrada!

        var track = new TrackModel({_id: id});
        //this.tracks = new TracksCollection();
        track.fetch({

            success: function (model, response) {

                that.trackForm = new TrackForm({model: track});
                $('#main').html(that.trackForm.render(track.toJSON()).el);
                that.trackForm.start();

                _.each(model.attributes.sections, function(el) {
                    $('#panel-section').append(new TrackSectionListItem({model: new TrackSectionModel(el)}).render().el);
                });

                _.each(model.attributes.wpts, function(el) {
                    $('#panel-waypoint').append(new TrackWaypointListItem({model: new TrackWaypointModel(el)}).render().el);
                });

                $('.btn-popover').popover('hide');

            }
        });
    },

/***************************************************************************/
    starteo: function() {
  
    //    if (Backbone.history.fragment === '')
      //      app.navigate('#/accounts', true);
    }

});



$(function() {
    window.app = new Route();

// app.Accounts = new AccountsRouter();

//    app.Sites = new SitesRouter();
  //  app.Streamline = new TrackerStreamlineRouter();
    //app.Events = new EventRouter();

    Backbone.history.start();

    //app.starteo();
});
