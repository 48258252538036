$(document).on('traky_init', function() {
    traky.flash = (function() {
        var self = {};

        self.success = function(message, clear, timer) {
            self.alert('success', message, clear, timer);
        };

        self.error = function(message, clear, timer) {
            self.alert('error', message, clear, timer);
        };

        self.info = function(message, clear, timer) {
            self.alert('info', message, clear, timer);
        };

        self.warn = function(message, clear, timer) {
            self.alert('warning', message, clear, timer);
        };

        self.alert = function(type, message, clear, timer) {

            if (typeof timer === 'undefined') timer = 5;

            if (self.isDuplicated(message)) {
                return false;
            }

            if (clear) {
                self.clear();
            }

            // preparar template
            var data = {message: message};
            var alerta = $(JST['alert-'+type](data));

            // contenedor de alertas
            var flashalerts = self.getObject();
            flashalerts.append(alerta);

            alerta
                .removeClass('hide')
                .hide()
                .show('fast');


            notify[type](message, ~clear);

            if (timer) {
                setTimeout(function() {
                    alerta.hide('fast', function() {
                        $(this).remove();
                    });
                }, timer * 1000);
            }
        };

        self.clear = function() {
            var flashalerts = self.getObject();
            flashalerts.find('.alert').hide('fast', function() {
                $(this).remove();
            });
        };

        self.isDuplicated = function(message) {
            var elem = self.getObject();
            var alerts = elem.find('.alert');
            for (var i = 0; i < alerts.length; i++) {
                var text = $(alerts[i])
                    .clone()
                    .children()
                    .remove()
                    .end()
                    .text();

                if ($.trim(text) === message) {
                    return true;
                }
            }
            return false;
        };

        self.getObject = function() {
            var flashalerts = $('.flashalerts').first();
            // comprobar si hay algún modal visible con soporte para flashalerts
            var modal = $('.modal').filter(':visible');
            if (modal.length) {
              
                var modalalert = modal.find('.flashalerts');
                if (modalalert) {
                    flashalerts = modalalert;
                }
            }

            return flashalerts;
        };

        return self;
    })();
});
