window.TrackWaypointListItem = Backbone.View.extend({

    tagName: 'p',
    template: JST["track-waypoint-list-item"],

    events: {
        "click .edit-waypoint": "editItem",
        "click .remove-waypoint": "deleteItem",
        'dblclick td[data-inline-edit]': traky.inlineEdit,
        'click .btn-popover': 'show_popover'
    },

    deleteItem: function(ev) {

        var self = this;

        bootbox.confirm("Seguro que deseas eliminar este waypoint?", function(result) {
            if (result) {
                self.$el.fadeOut('fast', function() {
                    $.ajax({
                        url: '/api/tracks/' + self.model.attributes.track_id + '/waypoint/' + self.model.id,
                        type: 'DELETE',
                        success: function(result) {
                            notify.info('Waypoint eliminado.', true);
                            self.remove();
                        },
                        error: function() {
                            self.$el.fadeIn();
                            bootbox.alert('Ha ocurrido un error, por favor, inténtalo de nuevo.');
                        }
                    });
                });
            }
        });

    },

    editItem: function(event) {
        event.preventDefault();

        $('#waypoint-name').val(this.model.attributes.name);
        $('#waypoint-type').val(this.model.attributes.type);
        $('#waypoint-color').val(this.model.attributes.color);
        $('#waypoint-location').val(this.model.attributes.location);
        $('#waypoint-map-icon').val(this.model.attributes.map_icon);
        $('#waypoint-graph-icon').val(this.model.attributes.graph_icon);
        $('#waypoint-show-on-map').prop('checked',this.model.attributes.show_on_map);
        $('#waypoint-show-on-graph').prop('checked',this.model.attributes.show_on_graph);
        $('#waypoint-track-index').val(this.model.attributes.track_index);
        $('#waypoint-desc').val(this.model.attributes.desc);
        $('#waypoint-index-type option[value="' + this.model.attributes.index_type + '"]').prop('selected',true);
        $('#waypoint-tags').val(this.model.attributes.tags);

        $('#waypoints-modal').data('waypointid', this.model.id);

    },

    show_popover: function() {


    },

    render: function() {
        if (!traky.checkLogin()) {
            return false;
        }
        var data = this.model;
        this.$el.html(this.template(data.toJSON()));
        return this;
    }
});
