window.EventsListItemView = Backbone.View.extend({

    tagName: 'tr',
    template: JST["events-list-item"],
    events: {
        "click .editEvent": "editItem",
        "click .deleteEvent": "deleteItem"
    },

    deleteItem: function(event) {
        event.preventDefault();
        var self = this;
        bootbox.confirm("Seguro que deseas eliminar este evento?", function(result) {
            if(result) {
                self.model.destroy({
                    success: function() {
                        self.remove();
                    },
                    error: function() {

                    }
                });
            }
        });
    },

    editItem: function(event) {
        event.preventDefault();
        app.navigate('admin/events/edit/'+ this.model.get('_id'),true);
        this.EventsForm = new EventsForm({model: this.model});
        var data = this.model.toJSON();
        $('#main').html(this.EventsForm.render(data).el);
        $('#page-title').text('Editar evento');
        traky.events.init(data);
    },

    render:function () {
        if (!traky.checkLogin) {return false;}
        var data = this.model.toJSON();
        //epicEditor.save().editor.textContent = data.detail.content;
        data.ntrackers = "-";
        data.nusers = "-";
        if(data.trackers2) data.ntrackers = data.trackers2.length;
        if(data.users2) data.nusers = data.users2.length;

        data.start_date_formatted = !data.start_date ? '-' : traky.dateFormatted(data.start_date);

        data.end_date_formatted = !data.stop_date ? '-' : traky.dateFormatted(data.stop_date);

      //adela
      //  this.$el.html(Handlebars.compile(JST["events/events-list-item.hbs"](data)));
      this.$el.html(this.template(data));
        return this;
    }
});
