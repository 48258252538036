window.EventsFilesListItemView = Backbone.View.extend({

    tagName: 'tr',
    className: 'file-row',
    template: JST["events/events-files-list-item.hbs"],

    events: {
        "click .file-remove": "fileRemove",
        "click .file-edit": "fileEdit"
    },

    initialize: function() {
        this.model.bind('change', this.render, this);
        this.model.bind('remove', this.remove, this);
    },

    doRemove: function() {
        var that = this;
        this.model.destroy({
            success: function() {
                notify.success('Eliminando!', true);
                that.undelegateEvents();
                that.$el.removeData().unbind().remove();
            },
            error: function() {
                notify.success('Error! Inténtalo de nuevo.', true);
            }
        });
    },

    // wrapper para doRemove. Habilita la función ctrl+click
    fileRemove: function(e) {
        if (e.ctrlKey) {
            return this.doRemove();
        }

        var that = this;
        return bootbox.confirm('Seguro que deseas eliminar este File?', function(result) {
            if (result) {
                that.doRemove();
                bootbox.alert('El file se ha desvinculado del evento, pero no ha sido eliminado.');
            }
        });
    },

    fileEdit: function() {
        new EventsFileForm({model:this.model}).render();
    },

    render:function () {
        var data = this.model.toJSON();
        this.$el.html(this.template(data));
        return this;
    }
});
