window.EventsForm = Backbone.View.extend(
{
  tagName: 'div',
  className: 'eventsForm',
  template: JST["events-form"],
  autoslug: true,

  events:
  {
    'click #parse-title': 'parseTitle',
    'keyup #title': 'changeTitle',
    'keyup #slug': 'changeSlug',

    'click #btn-add-event': 'add',
    'click #btn-add-back-event': 'add',
    'click #cancelEditEvent': 'cancel_edit_event',
    'click #event-delete': 'remove',

    'click #btn-add-user': 'user_add',
    'click .btn-edit-user': 'user_edit',
    'click .user-remove': 'user_remove',
    'click #users-save-all': 'user_save_all',
    'click #event-execute-action': 'execute_action',
    'click #users-check-all': 'users_check_all',

    'click .remove-filter': 'remove_filter',

    'click .current-date': 'current_date',
    'keyup #name-user': 'name_required',


    'click .open-settings-form': 'openEventSettings',

    'change #event-actions': 'eventActionsOptions',
    'click #remove-start-date': 'remove_start_date',
    'keyup #start-date': 'start_change_date'
  },

  // Meterlo en Global
  actions:
  [
    {
      name: '[Close] Free trackers',
      action: 'actions/freetrackers'
    },

    {
      name: '[Beta] Fill users2',
      action: 'actions/fillusers2'
    },

    {
      name: '[Beta] Fill users',
      action: 'actions/fillusers'
    },

    {
      name: '[Beta] Auto users2',
      action: 'actions/autousers'
    },

    {
      name: '[Beta] Auto users2 reset',
      action: 'actions/resetautousers'
    },

    {
      name: '[Beta] Auto Trackers To Users',
      action: 'actions/trackerstousers'
    },

    {
      name: 'Colorea usuarios',
      action: 'users/randomcolors'
    },

    {
      name: 'Genera iconos',
      action: 'users/generateicons'
    },

    {
      name: 'Genera Users Sessions',
      action: 'users/updatesessions'
    },

    {
      name: 'Set Locations CEEI',
      action: 'trackers/setlocation/ceei'
    },

    {
      name: 'Set Locations OUT',
      action: 'trackers/setlocation/out'
    }
  ],

  nuevo:false,

  initialize: function(options)
  {
   
    options = options || {};
    this.usersView = options.usersView || false;
    
    this.trackersView = options.trackersView || false;

    this.groupsView = options.groupsView || false;
    this.sponsorsView = options.sponsorsView || false;
    //this.commentsView = options.commentsView || false;
    //this.filesView = options.filesView || false;
    this.sessionsView = options.sessionsView || false;
    //this.mediaView = options.mediaView || false;

    this.settings = {};

    if (!this.collection)
    {
     
      this.collection = new EventsCollection();

      if(!this.model)
      {
        this.nuevo=true;
       
        this.model=new EventsModel();
      }
    }
  },

  remove_start_date: function()
  {
   
    $('#start-date').val('');
    $('#start-date').data('millis', '');
  },

  start_change_date: function()
  {
   
    $('#start-date').data('keyboard', true);
    $('#start-date').trigger('changeDate');
  },

  renew_change_date: function()
  {
    $('#renew-date').data('keyboard', true);
    $('#renew-date').trigger('changeDate');
  },

  render: function(data)
  {
  

    if (!traky.checkLogin())
    {
      return false;
    }

    // si hay data estamos editando, sino creamos por defecto

    if (!data)
    {
      data =
      {
        add_or_edit: 'Agregar',
        title: '',
        slug: '',
        description: '',
        mode: 'add'
      };
    }
    else
    {
      data.mode = 'edit';
      data.add_or_edit = 'Editar';

      if (data.draw)
      {
        data.draw = JSON.stringify(data.draw, null, 4);
      }
    }

    data.actions = this.actions;

    if (data.start_date)
      data.start_date = traky.dateFormatted(data.start_date);

    if (data.stop_date)
      data.stop_date = traky.dateFormatted(data.stop_date);

    data.published = traky.dateFormatted(data.published);



    //$(this.el).html(JST['events/events-form.hbs'](data));
    //adela
    this.info = {};
    this.info.name=data.name;
   
    this.$el.html(this.template(data));
    /*  if (data.mode === 'add') {
      $(this.el).find('.hideOnAdd').addClass('hide');
    */

    if (this.usersView)
    {
     
      $(this.el).find('#users-wrap').empty().html(this.usersView.render(this.model.toJSON()).el);
      $(this.el).find('#trackers-wrap').empty().html(this.trackersView.render(this.model.toJSON()).el);
      $(this.el).find('#groups-wrap').empty().html(this.groupsView.render().el);
      $(this.el).find('#sponsors-wrap').empty().html(this.sponsorsView.render().el);
      //$(this.el).find('#comments-wrap').empty().html(this.commentsView.render().el);
      //$(this.el).find('#files-wrap').empty().html(this.filesView.render().el);
      $(this.el).find('#sessions-wrap').empty().html(this.sessionsView.render().el);
      //$(this.el).find('#media-wrap').empty().html(this.mediaView.render().el);
    }
    else
    {
    
    }

    return this;
  },

  cancel_edit_event: function()
  {
   
    traky.events.reset(this.nuevo);
    //traky.trackers.reset();

  },

  add: function(e)
  {
    // compobar que el titulo no este vacio
    e.preventDefault();

    var event;
    var eventData = this.prepareForm(e);

    if (eventData.name.trim()==='')
    {
      //  alert(" el titulo esta vacio");
      //  $.pnotify_remove_all();
   
      bootbox.alert('El título no puede estar vacio.');
      notify.success('El título no puede estar vacio', true);
    }
    else
    {
      if (eventData) {
     
      if (this.model)
      {
        event = this.model;
      }
      else
      {
        event = new EventsModel();
      }

      event.save(eventData,
      {
        success: function(model)
        {
          // quitar aviso al cerrar ventana
          traky.unloadMessage = false;

          // recargamos la pagina (quizá se podría hacer un reset como en trackers)
          // app.navigate('admin/events/edit/' + model.get('_id'), true);
          $.pnotify_remove_all();
          notify.success('Evento guardado correctamente', true);

          var date = new Date();
          var h = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
          var m = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();

          $('#alert-event').remove();
          $('#events-form').prepend('<div class="alert alert-success" id="alert-event">Evento guardado a las <strong>' + h + ':' + m + '</strong><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button></div>');

          // adela volver a cargar el documento
          //  alert(" se ha guardado el evento");

          var _id=model.get('_id');

          alert("guardado id:"+'admin/events/edit/' + _id);
          app.navigate('admin/events/edit/' + _id, true);
        },

        error: function(model, err)
        {
       

          if (err.hasOwnProperty('responseJSON'))
          {
            if (err.responseJSON.hasOwnProperty('message'))
            {
              notify.error(err.responseJSON.message, true);
            }
            else if (err.responseJSON.hasOwnProperty('error'))
            {
              notify.error(err.responseJSON.error.message, true);
            }
          }
          else
          {
            notify.error('Error inesperado, intenta de nuevo', true);
          }
        }
      }
    );
        }

      }// comprobacion de nombre no vacio
  },

    remove: function(e) {
        e.preventDefault();
        bootbox.confirm("Seguro que deseas eliminar el evento?", function(result) {
            if (result) {
                this.model.destroy({
                    success: function() {
                        notify.success('El evento ha sido eliminado', true);
                        app.navigate('admin/events', true);
                    }
                });
            }
        });
    },

    remove_filter: function() {
        $('#trackers-typeahead').val('');
    },

    prepareForm: function(e) {
    
      var start_date= $('#start_date').val();
       
        start_date= traky.ymd1timestamp(start_date);
  

     var stop_date= $('#stop_date').val();

     
         stop_date= traky.ymd1timestamp(stop_date);
         
      var published= traky.strDateToMongo($('#published').data('millis'));
     

        

      var eventSettings={
        adventure_link: $('#adventure_link').val(),
        adventure_logo: $('#adventure_logo').val(),
        border_color: $('#border_color').val(),
        comment_header: $('#comment_header').val(),
        custom_html: $('#custom_html').val(),
        facebook: $('#facebook').val(),
        facebook_page: $('#facebook_page').val(),
        facebook_page_img: $('#facebook_page_img').val(),
        facebook_page_msg: $('#facebook_page_msg').val(),
        feed_rss: $('#feed_rss').val(),
        flood_comments: $('#flood_comments').val(),
       // google_noindex: $('#google_noindex').val(),
       google_noindex: $('#google_noindex').is(':checked'),
        has_dorsales: $('#has_dorsales').is(':checked'),
        has_sprites: $('#has_sprites').is(':checked'),
        header_color: $('#header_color').val(),
        header_image: $('#header_image').val(),
        header_mov_image: $('#header_mov_image').val(),
        header_size: $('#header_size').val(),
        iframe_info: $('#iframe_info').val(),
        lang: $('#lang').val(),
        map_start_position: $('#map_start_position').val(),
        map_start_zoom: $('#map_start_zoom').val(),
        map_type: $('#map_type').val(),
        media_bar: $('#media_bar').val(),
        mediabar_order: $('#mediabar_order').val(),
        mediabar_show: $('#mediabar_show').val(),
        mostrar_chat: $('#mostrar_chat').is(':checked'),
        mostrar_soporte: $('#mostrar_soporte').is(':checked'),
        mostrar_staff: $('#mostrar_staff').is(':checked'),
        mostrar_track: $('#mostrar_track').is(':checked'),
        participantes: $('#participantes').is(':checked'),
        sidebar_color: $('#sidebar_color').val(),
        sidebar_text_color: $('#sidebar_text_color').val(),
        social_url: $('#social_url').val(),
        spot_logo: $('#spot_logo').is(':checked'),
        tmw_unidades: $('#tmw_unidades').val(),
        twitter: $('#twitter').val(),
        twitter_text: $('#twitter_text').val(),
        user_display_text: $('#user_display_text').val(),
        zoomcontrol: $('#zoomcontrol').is(':checked')
      }

      var eventData={
        settings: eventSettings,

        event_id: $('#event_id').val(),
        name: $('#title').val(),
        slug: $('#slug').val(),
        start_date:start_date,
        stop_date: stop_date,
        published: traky.strDateToMongo($('#published').data('millis')),
      }
         return eventData;
    },

    execute_action: function() {

        var action = $('#event-actions').val();

        if (action === "") {
            bootbox.alert('No has seleccionado eventos.');
            return;
        }

        var data = {icon_label: false};
        var action_label = $('#actions-options-value').val();
        if (action_label) {
            data.icon_label = action_label;
        }

        var url = this.model.url() + "/" + action;

   

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: data,
            success: function() {
                document.location.reload(); // TODO fetch collection and render!
            },
            error: function() {
                bootbox.alert('Error al ejecutar la acción');
            }
        });
    },

    eventActionsOptions: function(e) {
        var el = $(e.currentTarget);
        if (el.val() === 'users/generateicons') {
            return $('#actions-options').removeClass('hide');
        }

        return $('#actions-options').addClass('hide');
    },

    openEventSettings: function(e) {
        e.preventDefault();
        var settings = new EventSettingsForm({model: this.model});
        settings.render();
    },

    // Events
    // ---------------------------------------------------------------
    current_date: function(el) {
        var _id = $(el.currentTarget).attr('id');
        if (_id.substring(8) === 'startDate')
            $('#startDate').val(traky.dateFormatted(new Date()));
        else if (_id.substring(8) === 'endDate')
            $('#endDate').val(traky.dateFormatted(new Date()));
        else if (_id.substring(8) === 'published')
            $('#published').val(traky.dateFormatted(new Date()));
    },

    name_required: function() {
        $('.group-title').removeClass('has-error');
    },

    title_required: function() {
        $('.group-title').removeClass('has-error');
    },

   changeTitle: function(e) {
       var $el = $(e.currentTarget);
       var title = $el.val();

       this.model.set('name', title);
     
       if (this.autoslug) {

           var slug = traky.slugs.parse(title);
           this.model.set('slug', slug);
           this.$el.find('#slug').val(slug);
       }
   },

   changeSlug: function(e) {
   
       this.autoslug = false;
   },

   parseTitle: function() {
   
       var title = this.$el.find('#title').val();
       var slug = traky.slugs.parse(title);
       this.model.set('slug', slug);
       this.$el.find('#slug').val(slug);
   }

});
