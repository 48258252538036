window.TrackForm = Backbone.View.extend({

    tagName: 'div',
    className: 'trackForm',
    template: JST["track-form"],

    events: {
        'click #edit-track': 'save',
        'click #attach-file': 'attach',
        'change input[type="text"]': 'changed',
        'click #processFile': 'processFile',
        'click #exportToCDN': 'exportToCDN',
        'click #previewCDN': 'previewCDN',
        'click #getElevation': 'getElevation',
        'click #save-waypoint': 'save_waypoint',
        'click .remove-waypoint': 'remove_waypoint',
        'click #save-section': 'save_section',
        'click .remove-section': 'remove_section',
        'click #show-new-section': 'show_new_section',
        'click #show-new-waypoint': 'show_new_waypoint'
    },

    initialize: function() {
        if (!this.model) {
            this.model = new TrackModel();
            console.log("Nuevo modelo");
            console.log(this.model);
        }
    },

    save_waypoint: function() {

        var self = this;

        var data = {
            name: $('#waypoint-name').val(),
            type: $('#waypoint-type').val(),
            location: $('#waypoint-location').val(),
            color: $('#waypoint-color').val(),
            map_icon: $('#waypoint-map-icon').val(),
            graph_icon: $('#waypoint-graph-icon').val(),
            show_on_map: $('#waypoint-show-on-map').prop('checked'),
            show_on_graph: $('#waypoint-show-on-graph').prop('checked'),
            track_index: $('#waypoint-track-index').val(),
            desc: $('#waypoint-desc').val(),
            index_type: $('#waypoint-index-type').val(),
            tags: $('#waypoint-tags').val().split(',')
        };


        if (!$('#waypoints-modal').data('waypointid').length) {
            $.post("/api/tracks/waypoint/" + this.model.id, data, function(data) {
                $('#waypoints-modal').modal('hide');
                $('#panel-waypoint').append(new TrackSectionListItem({
                        model: new TrackSectionModel(data)
                    }).render().el);
                notify.success('Waypoint añadido correctamente.', true);
            });
            return;
        }


        $.ajax({
            url: '/api/tracks/' + this.model.id + '/waypoint/' + $('#waypoints-modal').data('waypointid'),
            type: 'put',
            data: data,
            success: function(result) {
                $('#waypoints-modal').modal('hide');
                notify.success('Waypoint actualizado correctamente.', true);
                app.navigate('#admin/tracks/edit/' + self.model.id, {
                    trigger: true
                });
            }
        });


    },

    // remove_waypoint: function(ev) {

    //     var el = $(ev.target).data('id') ? $(ev.target).data('id') : $(ev.target.parentNode).data('id');

    //     $.ajax({
    //         url: '/api/tracks/' + this.model.id + '/waypoint/' + el,
    //         type: 'DELETE',
    //         success: function(result) {
    //             // Do something with the result
    //         }
    //     });

    // },

    save_section: function() {

        var self = this;

        var data = {
            name: $('#section-name').val(),
            color: $('#section-color').val(),
            show_on_graph: $('#section-show-on-graph').prop('checked'),
            show_on_map: $('#section-show-on-map').prop('checked'),
            location_start: $('#section-location-start').val().split(','),
            location_end: $('#section-location-end').val().split(','),
            track_index_start: $('#track-index-start').val(),
            track_index_stop: $('#track-index-stop').val(),
            index_type: $('#section-index-type').val(),
            tags: $('#section-tags').val().split(',')
        };

        if (!$('#sections-modal').data('sectionid').length) {
            $.post("/api/tracks/section/" + this.model.id, data, function(data) {
                $('#sections-modal').modal('hide');
                $('#panel-section').append(new TrackSectionListItem({
                        model: new TrackSectionModel(data)
                    }).render().el);
                notify.success('Section añadido correctamente.', true);
            });
            return;
        }


        $.ajax({
            url: '/api/tracks/' + this.model.id + '/section/' + $('#sections-modal').data('sectionid'),
            type: 'put',
            data: data,
            success: function(result) {
                $('#sections-modal').modal('hide');
                notify.success('Section actualizado correctamente.', true);
                app.navigate('#admin/tracks/edit/' + self.model.id, {
                    trigger: true
                });
            }
        });


    },

    show_new_section: function() {
        $('#sections-modal').data('sectionid', '');
        $('#reset-form-section').trigger('click');
    },

    show_new_waypoint: function() {
        $('#waypoints-modal').data('waypointid', '');
        $('#reset-form-waypoint').trigger('click');
    },

    remove_section: function(ev) {

        // var el = $(ev.target).data('id') ? $(ev.target).data('id') : $(ev.target.parentNode).data('id');

        // $.ajax({
        //     url: '/api/tracks/'+this.model.id + '/section/' + el,
        //     type: 'DELETE',
        //     success: function(result) {
        //         // Do something with the result
        //     }
        // });

    },

    processFile: function() {
      console.log("processfile");
        this.model.set('processFile', true);
        this.save();

    },
    exportToCDN: function() {
        this.model.set('exportToCDN', true);
    },
    getElevation: function() {
        this.model.set('getElevation', true);
    },

    changed: function(e) {
        var el = $(e.currentTarget);
        var key = el.attr('id');
        var value = el.val();

        console.log(key + " " + value);

        this.model.set(key, value);

        traky.unloadMessage = true;
    },

    render: function(data) {

        $(this.el).html(this.template(data));
        traky.setHGroup('Nuevo track');
        if (!this.model.isNew()) {
            traky.setHGroup('Editar track');
        }

        return this;
    },

    start: function() {
        /*
        $('#date').datepicker({
            format: 'dd-mm-yyyy'
        });
        */
    },

    save: function() {

        console.log("saving");
        console.log(this.model);

        this.model.save(null, {
            success: function(model) {
                traky.unloadMessage = false;
                notify.success('El track se ha guardado', true);
                app.navigate('#admin/tracks/edit/' + model.get('_id'), {
                    trigger: true
                });
            },
            error: function(status) {
                notify.error('Ha ocurrido un error, inténtalo de nuevo. (code ' + status.status + ')', true);
            }
        });
    },
    attach: function() {
      //  alert("ATTACH funcition");
        this.attachForm = new AttachFileToTrackFormView({
            model: this.model
        });
        $(this.el).append(this.attachForm.render().el);

    },

    previewCDN: function() {
        // Descarga en full del track individual?
        this.previewTrackView = new PreviewTrackView({
            model: this.model
        });
        $(this.el).append(this.previewTrackView.render().el);
    }
});
