window.EventsUserUploadForm = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade',
    id: 'modal-user-upload',
    attributes: 'tabindex="-1" role="dialog" aria-labelledby="modalUserUploadLabel" aria-hidden="true"',
    template: JST['events/events-user-upload-form.hbs'],

    files: null,

    events: {
        'click #btn-save-file': 'save'
    },

    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        this.files = this.model.get('files') || [];
        this.$el.modal();
        return this;
    },

    upload: function(cb) {
        console.log('upload 1');
        $('#form-users-upload').ajaxSubmit({
            url: '/api/uploads/cdn',
            success: function(data) {
                console.log('upload 2');
                cb(data.file);
            },
            error: function(xhr) {
                console.log(xhr);
            }
        });
    },

    save: function() {
        var that = this;

        var file = {
            title: this.$el.find('#title').val(),
            url: this.$el.find('#url').val(),
            tags: this.$el.find('#tags').val().replace(/, /g, ',').split(','),
            _id: this.$el.find('file_id').val() || null
        };

        function save(file) {
            if (file._id) {
                if (!_.some(this.files, {_id: file._id})) {
                    return bootbox.alert('Error, parece que el archivo ha sido eliminado.');
                }

                _.each(this.files, function(_file) {
                    if (_file._id === file._id) {
                        _file = file;
                    }
                });
            }

            that.model.set('files', files);
            that.model.save(null, {
                success: function() {

                },
                error: function() {
                    bootbox.alert('Error al guardar el documento. Mira la consola del navegador');
                    console.log('El archivo se ha cargado pero el modelo de backbone no. Datos del archivo: ', data);
                }
            });
        }

        if (!file._id) {
            // nuevo
            this.upload(function(filepath) {
                console.log('on save');
                file.file = filepath;
                save(file);
            });

            return;
        }

        // editar existente
        if (this.$el.find('#file').val().length) {
            console.log('testeando');
        }

    }
});
