window.EventsSponsorsListItemView = Backbone.View.extend({

    tagName: 'tr',
    className: 'sponsor-row',
    template: JST['events/events-sponsors-list-item.hbs'],

    events: {
        "click .sponsor-remove": "sponsorRemove",
        "click .sponsor-edit": "sponsorEdit"
    },

    initialize: function() {
        this.model.bind('change', this.render, this);
        this.model.bind('remove', this.remove, this);
    },

    doRemove: function() {
        var that = this;
        this.model.destroy({
            success: function() {
                notify.success('Eliminando!', true);
                that.undelegateEvents();
                that.$el.removeData().unbind().remove();
            },
            error: function() {
                notify.success('Error! Inténtalo de nuevo.', true);
            }
        });
    },

    // wrapper para doRemove. Habilita la función ctrl+click
    sponsorRemove: function(e) {
        if (e.ctrlKey) {
            this.doRemove();
        } else {
            var that = this;
            bootbox.confirm('Seguro que deseas eliminar este Sponsor?', function(result) {
                if (result) {
                    that.doRemove();
                    bootbox.alert('El sponsor se ha desvinculado del evento, pero no ha sido eliminado.');
                }
            });
        }
    },

    sponsorEdit: function() {
        new EventsSponsorForm({model:this.model}).render();
    },

    render:function () {
        var data = this.model.toJSON();
        this.$el.html(this.template(data));
        return this;
    }
});
