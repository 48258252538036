window.EventsCommentsListView = Backbone.View.extend({

    tagName: 'div',
    className: 'panel panel-default',
    template: JST['events/events-comments-list.hbs'],

    initialize: function(options) {
        this.listenTo(this.collection, 'add', this.add);
        this.users = options.users;
    },

    add: function(comment) {
        var render = new EventsCommentsListItemView({
            model: comment,
            users: this.users
        }).render().el;
        $(this.el).find('#comments').append(render);
    },

    render: function() {
        this.$el.html(this.template());
        return this;
    }
});
