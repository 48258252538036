window.EventsSessionForm = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade edit-session',
    template: JST["events-sessions-form"],
    attributes: {
        "tabindex":"-1",
        "role":"dialog",
        "aria-labelledby":"editUsers",
        "aria-hidden":"true"
    },

    previousAttibutes: {},

    events: {
        'click .session-save': 'save',
        'click .session-cancel': 'cancel',
        'click #generateStaticFile': 'generateStaticFile'
    },

    initialize: function() {
        this.previousAttibutes = this.model.toJSON();
    },

    cancel: function() {
        if(this.model.changedAttributes()) {
            if(confirm("El session se ha modificado, estás seguro de que no quieres guardar los cambios?")) {
                if(this.model.isNew()){
                    this.model.destroy();
                }
                else {
                    this.model.set(this.previousAttibutes);
                }
                this.$el.modal('hide');
            }
        }
        else {
            if(this.model.isNew()){
                this.model.destroy();
            }
            this.$el.modal('hide');
        }
    },

    generateStaticFile: function() {
        this.model.set('generateStaticFile',true);
    },

    render: function() {

        var self = this;
        var data = this.model.toJSON();

      //  this.$el.html(JST["events/events-sessions-form.hbs"](data));
      this.$el.html(this.template(data));
        $('#main').append(this.el);

        // modal
        this.$el.modal('show')
            .on('hidden.bs.modal',function(){
                self.undelegateEvents();
                self.$el.removeData().unbind().remove();
            });

        // guarda el grupo al pulsar enter sobre un input
        this.$el.keypress(function(event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode === 13) {
                self.save();
            }
        });

        return this;
    },

    save: function() {
        var self = this;

        var name = $('#session-item-name').val();

        var from = traky.dateToMongo($('#session-from').val());
        from = new Date(from);

        var to = traky.dateToMongo($('#session-to').val());
        to = new Date(to);

        var start = traky.dateToMongo($('#session-start').val());
        start = new Date(start);

        var range = [from.getTime(), to.getTime()];
        this.model.set('name', name);
        this.model.set('range', range);
        this.model.set('start_date', start.getTime());

        this.model.save({}, {
            success:function() {
                notify.info('Session actualizada', true);
                self.$el.modal('hide');
            },
            error: function() {
                notify.info('Error al guardar la sesión.', true);
            }
        });
    }
});
