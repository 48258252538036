window.TrackMapView = Backbone.View.extend({
    tagName: 'div',
    id: 'track-map-modal',
    className: 'modal fade',
    attributes: 'tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"',
    template: JST['tracks-map'],

    currentLine: null,
    currentVertex: null,
    polylines: [],
    //adela a revisar
  //  popup: L.popup(),

    events: {

    },

    initialize: function(options) {

    },

    close: function() {
        var that = this;
        // evitar multiples creaciones del mismo modal
        this.$el.on('hidden.bs.modal', function() {
            that.unbind();
            that.remove();
        });
    },

    render: function() {

        this.$el.html(this.template());
        this.close();
        this.$el.modal();
        this.initMap();

        return this;
    },

    initMap: function() {
        var that = this;

        this.$el.on('shown.bs.modal', function() {


            that.map = L.map(that.$('#mapa')[0], {
                editable: true,
                measureControl:true
            }).setView([55.75, 37.58], 10);


            L.tileLayer(tmw.config.maps, {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(that.map);

            /*
            that.map.on('click', function(e) {
                that.addPoint(e);
            });
            */

            that.map.invalidateSize();
            that.loadTrack();
        });
    },

    initFlot: function(trkpt) {

        var plot_data = [];
        var s1 = {
            color: "#" + this.model.get('color'),
            data: [],
            shadowSize: 0
        };

        for(var i in trkpt) {
            s1.data.push([trkpt[i][3],trkpt[i][2]]);
        }

        plot_data.push(s1);

        this.plot = $.plot("#modal-plot",plot_data);
    },

    loadTrack: function() {

        var that = this;
        var url = this.model.urlRoot + "/" +this.model.id+"/preview";
        // Hacer un fetch del modelo
        console.log(url);
        $.getJSON(url).done(function(data) {

            var latlngs = [];
            for (var i in data.trkpt) {

                var latLng = L.latLng(data.trkpt[i][1], data.trkpt[i][0]);
                latlngs.push(latLng);
            }

            that.drawTrack(latlngs);
            that.initFlot(data.trkpt);
        });
    },

    drawCircle: function(data) {

        var that = this;
        var radius = data.radius;
        var endStart = false;

        var ICON_FLAG = 'http://icons.iconarchive.com/icons/iconshock/super-vista-business/128/checkered-flag-icon.png';
        var iconFlag = L.icon({
            iconUrl: ICON_FLAG,
            iconSize: [25, 31]
        });

        if (data.actions._in) {
            for (var i = 0, len = data.actions._in.length; i < len; i++) {
                if (data.actions._in[i] === 'sessionend' || data.actions._in[i] === 'sessionstart')
                    endStart = true;
            }
        }

        if (data.actions._out) {
            for (var i = 0, len = data.actions._out.length; i < len; i++) {
                if (data.actions._out[i] === 'sessionend' || data.actions._out[i] === 'sessionstart')
                    endStart = true;
            }
        }

        var options = {
            color: 'red',
            fillColor: 'red',
            fill: true,
            fillOpacity: 0.4
        };

        if (endStart) {
            options = {
                color: 'black',
                fillColor: 'black',
                fill: true,
                fillOpacity: 0.4
            };

            L.marker([data.location[1], data.location[0]], {
                icon: iconFlag
            }).addTo(this.map);
        }

        var circle = L.circle([data.location[1], data.location[0]], radius, options);

        circle.bindLabel(data.name,{ noHide: true });

        circle.on('dblclick', function() {
            that.map.setZoom(15);
            that.map.setView([data.location[1], data.location[0]]);
        });

        return circle;
    },

    getWayointsToStage: function(stage) {

        var that = this;
        stage = stage || "ET1";
        var layer = new L.LayerGroup();
        var url = "http://worker01.tracemyway.com:3000/leaderboards/waypoints/"+stage;

        $.getJSON(url, function(waypoints) {
            _.each(waypoints, function(waypoint) {
                that.drawCircle(waypoint).addTo(layer);
                //waypointsList.push(waypoint);
            });
        });

        layer.addTo(this.map);
    },

    loadPolylines: function(trackUrl) {

        // Cargar desde mongo

        var that = this;

        $.getJSON(trackUrl)

            .done(function(data) {

                var keys = Object.keys(data);
                if(!keys) return; // Aqui error flash

                var track = data[keys[0]];
                if(!track) return; // Aqui error flash

                var latlng = [];

                for (var i in track) {

                    var point = track[i];
                    var latLng = L.latLng(point.loc[1], point.loc[0]);

                    // custom track para latLng
                    latLng.sog = point.sog;
                    latLng.cog = point.cog;
                    latLng.gps = point.gps;
                    latLng.gpsh = new Date(point.gps).toString();
                    latLng.type = point.type;

                    latlng.push(latLng);
                }

                var line = L.polyline(latlng, {color: 'red'}).addTo(that.map);

                // Al hacer click creamos un poliline nuevo solo con lo que hay en los bounds
                line.on('click', function() {

                    // Solo con lo que hay en los bounds, pero menos de 1000
                    var bounds = that.map.getBounds();
                    var path = this.getLatLngs();
                    var pathInBounds = [];
                    for(var i in path) {
                        if(bounds.contains(path[i])) pathInBounds.push(path[i]);
                    }

                    if(pathInBounds.length>500) {
                        alert('no va');
                        return;
                    }

                    that.map.removeLayer(this);

                    var editable = L.polyline(pathInBounds, {color: 'blue'}).addTo(that.map);
                    that.useLine(editable);

                    editable.on('editable:vertex:altclick', function(e) {
                    //editable.on('editable:vertex:ctrlclick', function(e) {

                        // almacenamos el vertice actual
                        console.log(e.latlng.source);
                        that.currentVertex = new PositionModel(e.latlng.source);

                        //JST['lboard/lboard-map-popup.hbs'](e.latlng)

                        // editar vertice (latlng)
                        that.popup
                            .setLatLng(e.latlng)
                            .setContent()
                            .openOn(that.map);
                    });

                        //that.useLine(this);
                });

                // al hacer ctrlclick sobre un vertice se abre un popup

                that.map.fitBounds(line.getBounds());
            }
        );
    },

    drawTrack: function(latlngs) {

        console.log('drawTrack');

        var that = this;
        var line = L.polyline(latlngs, {color: 'red'}).addTo(this.map);
        this.map.fitBounds(line.getBounds());

        // Al hacer click creamos un poliline nuevo solo con lo que hay en los bounds
        line.on('click', function() {

            // Solo con lo que hay en los bounds, pero menos de 1000
            var bounds = that.map.getBounds();
            var path = this.getLatLngs();
            var pathInBounds = [];
            for(var i in path) {
                if(bounds.contains(path[i])) pathInBounds.push(path[i]);
            }

            if(pathInBounds.length>500) {
                notify.notice('Demasiados puntos en track, haz zoom',true);
                return;
            }

            that.map.removeLayer(this);

            var editable = L.polyline(pathInBounds, {color: 'blue'}).addTo(that.map);
            that.useLine(editable);

            editable.on('editable:vertex:altclick', function(e) {
            //editable.on('editable:vertex:ctrlclick', function(e) {

                // almacenamos el vertice actual
                if(that.currentVertex) {
                    that.popup
                            .setLatLng(e.latlng)
                            .openOn(that.map);
                    return;
                }

                if(!e.vertex.latlng.source) {
                    notify.error('vertex doesnt have source',true);
                    return;
                }

                that.currentVertex = e.vertex.latlng;
                that.currentVertex.model = new PositionModel(e.vertex.latlng.source);

                that.currentVertex.model.fetch({
                    success: function() {

                        console.log(that.currentVertex);

                        that.currentVertex.model.set('location',[that.currentVertex.lng,that.currentVertex.lat]);

                        var popupView = new MapPositionEditView({
                            model: that.currentVertex.model,
                            parent: that,
                            vertex: that.currentVertex
                        });

                        that.popup
                            .setLatLng(e.latlng)
                            .setContent(popupView.render().$el[0])
                            .openOn(that.map);
                    }
                });

            });
            //that.useLine(this);

            editable.on('editable:vertex:dragend', function(e) {
                if(that.currentVertex)
                    that.currentVertex.model.set('location',[that.currentVertex.lng,that.currentVertex.lat]);
            });
        });
    },

    loadPolylinesFromMongo: function(track) {

        // Cargar desde mongo, tenemos _id
        var latlngs = [];
        for (var i in track) {

            var latLng = L.latLng(track[i].location[1], track[i].location[0]);
            latLng.source = track[i];
            latLng.model = {};
            latlngs.push(latLng);
        }

        this.drawTrack(latlngs);
    },

    useLine: function(line) {
        if (this.currentLine) {
            this.currentLine.disableEdit();
            this.currentLine = null;
            return;
        }
        this.currentLine = line;
        line.enableEdit();
    },

    addPoint: function(e) {
        if (!this.currentLine) return;
        this.currentLine.addLatLng(e.latlng);

        // reset editable
        this.currentLine.disableEdit();
        this.currentLine.enableEdit();
    }
});
