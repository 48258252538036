window.TrackersForm = Backbone.View.extend({

    tagName: 'div',
    className: 'trackersForm',
      template: JST["trackers-form"],
    events: {
        'click #btn-add-tracker': 'add',
        'click #btn-add-back-tracker': 'add',
        'click .tracker-remove': 'remove',
        'click #tracker-notes-add': 'notes_add',
        'click .tracker-notes-remove': 'notes_remove',

        'click #cancelEditUser': 'cancel_edit_user',
        'change form input': 'unloadMessage',

        'click .latest-positions': 'getLatestPositions',

        'click #remove-purchased-date': 'remove_purchased_date',
        'click #remove-renew-date': 'remove_renew_date',
        'keyup #purchased-date': 'purchase_change_date',
        'keyup #renew-date': 'renew_change_date'


    },
    nuevo:false,
    latestPositions: null,
    map: null,
    mapTracks: {},
    mapMarkers: [],
    selectedDates: [],
    selectedAvg: [],

    remove_purchased_date: function() {
        $('#purchased-date').val('');
        $('#purchased-date').data('millis', '');
    },

    remove_renew_date: function() {
        $('#renew-date').val('');
        $('#renew-date').data('millis', '');
    },

    purchase_change_date: function() {
        $('#purchase-date').data('keyboard', true);
        $('#purchase-date').trigger('changeDate');
    },

    renew_change_date: function() {
        $('#renew-date').data('keyboard', true);
        $('#renew-date').trigger('changeDate');
    },

    calcSelectedAverage: function() {

     
        var self = this;

        this.selectedAvg = [];
        var prev_timestamp = null;

        this.selectedDates = _.uniq(this.selectedDates);
        this.selectedDates.sort();

     

        if (this.selectedDates.length > 0) {
  
        }

        _.each(this.selectedDates, function(timestamp) {

            if (prev_timestamp) {
                self.selectedAvg.push((prev_timestamp - timestamp) / 1000);
            }
            prev_timestamp = timestamp;

        });

        var sum = 0;
        _.each(self.selectedAvg, function(interval) {
            sum += interval;
        });

    
    },

    initialize: function(options) {
    
        options = options || {};
        this.commandsView = options.commandsView || false;
        if (!this.collection) {
    
           this.collection = new TrackersCollection();
           if(!this.model){
             this.nuevo=true;
             // escondemos metadata
            
            this.model=new TrackersModel();
            }
         }
    },

    unloadMessage: function() {
        traky.unloadMessage = true;
    },

    render: function(data) {
      var data = this.model.toJSON();
      data.isNew = this.model.isNew();

      traky.setHGroup( ' Tracker');


        this.notes = [];

        var that = this;

        if (!traky.checkLogin()) {
            return false;
        }


        if (data) {
            _.each(data.notes, function(notes) {
                var d = new Date(notes.date);
                notes.date = traky.dateFormatted(d);
                that.notes.push(notes);
            });

            if (data.metadata) {
                data.metadata = JSON.stringify(data.metadata, null, 4);
            }
        }


        if (data.purchased_date) {

          //  data.purchased_date_formatted = traky.dateFormatted(data.purchased_date).slice(0, -6);

              data.purchased_date_formatted = traky.dateFormatted(data.purchased_date);
            //data.purchased_time_ago = time_ago(new Date(data.purchased_date) / 1000, 3);
        }

        if (data.renew_date) {
          //  data.renew_date_formatted = traky.dateFormatted(data.renew_date).slice(0, -6);
              data.renew_date_formatted = traky.dateFormatted(data.renew_date);
          //  data.renew_time_ago = time_ago(new Date(data.renew_date) / 1000, 3);
        }
      

        this.$el.html(this.template(data));
      //  $(this.el).html(Handlebars.compile(JST['trackers-form'](data)));
 
    //    $(this.el).find('#commands-wrap').empty().html(this.commandsView.render().el);
        if (this.nuevo){
   
              $(this.el).find('#div_metadata').hide();
          //hacemos los inputs editables
            $(this.el).find('#name').removeAttr('disabled');
             $(this.el).find('#boton_name').removeClass("glyphicon-pencil");
             $(this.el).find('#boton_name').addClass("glyphicon-ok");

             $(this.el).find('#tracker_id').removeAttr('disabled');
              $(this.el).find('#boton_id').removeClass("glyphicon-pencil");
              $(this.el).find('#boton_id').addClass("glyphicon-ok");


        }
        return this;
    },

    add: function(e) {
        e.preventDefault();
        var tracker;
        var trackerData = this.prepareForm(e);
       //if (trackerData) {

            if (this.model) {
                tracker = this.model;
            } else {
                tracker = new TrackersModel();
            }

            tracker.save(trackerData, {
                success: function(model) {
                    traky.unloadMessage = false;
                    //app.navigate('admin/trackers/edit/' + model.get('_id'), true);
                  //  $.pnotify_remove_all();
                    //notify.success('Tracker guardado correctamente', true);
                    var date = new Date();
                    var h = date.getHours() <= 9 ? '0' + date.getHours() : date.getHours();
                    var m = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes();
                    $('#alert-tracker').remove();
                    $('#events-form').prepend('<div class="alert alert-success" id="alert-tracker">Tracker guardado a las <strong>' + h + ':' + m + '</strong><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button></div>');
                    if (e.target.id === 'btn-add-back-tracker')
                
                    //  traky.trackers.reset(this.model.isNew);
                  //   traky.trackers.reset();

                    $('#purchased-time-ago').html(model.attributes.purchased_date ? time_ago(model.attributes.purchased_date/1000, 3) : '');
                    $('#renew-time-ago').html(model.attributes.renew_date ? time_ago(model.attributes.renew_date/1000, 3) : '');
                },

                error: function(model, err) {
                
                    // TODO: el servidor debe responder con codigos http validos
                    //       segun el tipo de error (500, 400, 401...)
                    if (err.hasOwnProperty('responseJSON')) {
                        if (err.responseJSON.hasOwnProperty('message')) {
                            notify.error(err.responseJSON.message, true);

                        } else if (err.responseJSON.hasOwnProperty('error')) {
                            notify.error(err.responseJSON.error.message, true);
                        }

                    } else {
                      //  notify.error('Error inesperado, intenta de nuevo', true);
                      alert("Error:"+err)
                    }
          }
            });
      //  }
    },

    prepareForm: function(e) {
    

        var trackerData = {
            name: $('#name').val(),
            tracker_id: $('#tracker_id').val(),
            user_id: $('#user_id').val(),
            event_id: $('#event_id').val(),
            location: $('#location').val(),
            type: $('#type').val(),
            status: $('#status').val(),
            label: $('#label').val(),
            notes: [],

            purchased_date: $('#purchased-date').data('millis'),
            renew_date: $('#renew-date').data('millis')

        };

        var metadata = traky.trackers.metadata.getValue();
     
        if (metadata !== '') {
            try {
                trackerData.metadata = JSON.parse(metadata);
            } catch (err) {
                notify.error('El campo metadata no tiene un formato valido.', true);
                return false;
            }
        }

        _.each(this.notes, function(note) {
            if (!note) return;
            note.date = traky.dateToMongo(note.date);
            trackerData.notes.push(note);
        });

        return trackerData;
    },

    remove: function(e) {
        e.preventDefault();
        bootbox.confirm("Seguro que deseas eliminar este tracker?", function(result) {
            if (result) {
                this.model.destroy({
                    success: function() {
                        notify.success('El tracker ha sido eliminado', true);
                        app.navigate('admin/trackers', true);
                    }
                });
            }
        });
    },


    // Notes
    // -------------------------------------------------------------------------
    notes_setup: function() {
        $('#tracker-notes-text').val('');
    },

    notes_generate: function() {
    
        var data = {
            notes: this.notes
        };

    
        var notes = Handlebars.compile(JST['tracker-notes'](data));
        $('#notes').empty().append(notes);
    },

    notes_add: function() {
   
        var note = {
            text: $('#tracker-notes-text').val(),
            author: $.cookie('traky.name'),

            date: traky.dateFormatted(new Date())
        };

        if (_.isEmpty(note.text)) {
            bootbox.alert('Escribe la nota o pulsa botón cancelar.');
            return;
        }

        traky.unloadMessage = true;

        this.notes.push(note);
        this.notes_generate();
        $('#tracker-modal').modal('hide');
    },

    notes_remove: function(e) {
        var that = this;
        bootbox.confirm('Eliminar nota?', function(result) {
            if (result) {
                var index = $(e.target).closest('li').attr('data-index');
                that.notes.splice(index, 1);
                that.notes_generate();
            }
        });
    },

    cancel_edit_user: function() {
   
        traky.trackers.reset(this.nuevo);
    //   traky.trackers.reset();

    },

    // Mostrar las 5000 últimas posiciones de un tracker OJO con la consulta
    getLatestPositions: function() {

        var self = this;
    
        this.latestPositions = new TrackerPositions({
            id: this.model.id
        });

        this.latestPositions.fetch({
            success: function() {
              
                self.showLatestPositions();
            },
            error: function() {
            
            }
        });
    },

    showLatestPositions: function() {

        var self = this;
        this.$('#view-latest-positions').modal('show').on('shown.bs.modal', function() {
         
            self.initializeMap();
        });
    },

    initializeMap: function() {

        var self = this;
        var positions = this.latestPositions.toJSON().positions; // Esto tiene que tardar
        var bounds = new google.maps.LatLngBounds();

        var mapOptions = {
            zoom: 18,
            center: new google.maps.LatLng(50, 50),
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        this.map = new google.maps.Map($('#map-latestpositions')[0], mapOptions);

        var drawingManager = new google.maps.drawing.DrawingManager({
            drawingControlOptions: {
                drawingModes: [google.maps.drawing.OverlayType.RECTANGLE, google.maps.drawing.OverlayType.CIRCLE]
            },
            circleOptions: {
                fillColor: '#ffff00',
                fillOpacity: 0.5,
                strokeWeight: 1,
                clickable: false,
                zIndex: 1,
                editable: true
            },
            rectangleOptions: {
                fillColor: '#ffff00',
                fillOpacity: 0.5,
                strokeWeight: 1,
                clickable: false,
                zIndex: 1,
                editable: true
            }
        });

        drawingManager.setMap(this.map);

        google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {

            switch (event.type) {

                case google.maps.drawing.OverlayType.CIRCLE:
               
                    break;

                case google.maps.drawing.OverlayType.RECTANGLE:

                    // Determinar y mostrar todos los markers
                    var bounds = event.overlay.getBounds();

                    self.mapMarkers.forEach(function(marker) {
                        if (bounds.contains(marker.getPosition())) {
                            marker.setMap(self.map);
                            var timestamp = marker.get('gps_timestamp');
                            self.selectedDates.push(timestamp);
                        }
                    });

                    self.calcSelectedAverage();

                    break;

                default:
                    alert(event.type);
                    break;
            }

        });

        _.each(positions, function(position) {

            var latLng = new google.maps.LatLng(position.location[1], position.location[0]);

            if (!(self.mapTracks[position.event_id])) {
                self.mapTracks[position.event_id] = new google.maps.MVCArray();
            }

            self.mapTracks[position.event_id].push(latLng);
            var marker = new google.maps.Marker({
                position: latLng
            });
            marker.set('gps_timestamp', position.gps_timestamp);
            self.mapMarkers.push(marker);
            bounds.extend(latLng);
        });

        for (var i in this.mapTracks) {

            new google.maps.Polyline({
                map: self.map,
                path: self.mapTracks[i],
                strokeWeight: 1,
                strokeColor: this.get_random_color()
            });
        }

        // Pintar los polilyne

        this.map.fitBounds(bounds);
    },

    get_random_color: function() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    }
});
