window.PreviewTrackView = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade',
    id: 'modal-preview-track',
    attributes: 'tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"',
    template: JST['tracks/preview.hbs'],
    map: null,

    initialize: function() {
    },

    events: {
        'click #cancel-preview': 'cancel'
    },

    cancel: function() {
        this.$el.modal('hide');
        // TODO meter un remove en el evento de on hide
    },

    render: function(data) {

        console.log("Render", data);

        var self = this;

        this.$el.html(this.template(this.model.toJSON()));

        this.$el.modal({
            backdrop: 'static'
        });

        this.$el.modal().on(
            'shown.bs.modal',
            function() {

                var cmAttr = 'Map data &copy; 2011 OpenStreetMap contributors, Imagery &copy; 2011 CloudMade';

                self.map = L.map('map', {
                    center: [32.526042, -4.95921],
                    zoom: 7,
                    layers: [
                        L.tileLayer(tmw.config.maps, {
                            styleId: 22677,
                            attribution: cmAttr
                        })
                    ]
                });

                // Marker de start y de end
                console.log(self.model);
                var preview_url = "/api/tracks/"+self.model.id+"/preview";
                console.log("Esta es la URL",preview_url);
                // Substituir por AJAX para tener mejor el error onerror
                $.getJSON(preview_url, function(data) {

                    var latLngs = [];
                    for (var i = 0, points = data.trkpt.length; i < points; i++) {
                        latLngs.push([data.trkpt[i][1], data.trkpt[i][0]]);
                    }

                    console.log("#" + data.color);

                    var pol = L.polyline(latLngs, {
                        color: "#"+data.color,
                        smoothFactor: 1
                    }).addTo(self.map);

                    self.map.fitBounds(pol.getBounds());
                });
            }
        );

        return this;
    }
});
