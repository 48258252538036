window.EventsGroupsListView = Backbone.View.extend({

    tagName: 'div',
    className: 'panel panel-default',
    template: JST["groups-list"],

    events: {
        'click .add-group': 'addGroup'
    },

    initialize: function() {
      /*  _(this).bindAll('add');
        this.collection.bind('add', this.add);
*/
          this.listenTo(this.collection, 'add', this.add);
    },

    add: function(group) { // Añadido nuevo elemento a la coleccion
        var render = new EventsGroupsListItemView({model:group}).render().el;
        $(this.el).find('table tbody').append(render);
    },

    render: function() {
      //  this.$el.html(Handlebars.compile(JST["events/groups-list.hbs"]()));
        this.$el.html(this.template());
        return this;
    },

    addGroup: function() {
        var group = new EventGroup();
        this.collection.add(group);
        new EventsGroupForm({model:group}).render();
    }

});
