window.EventsFileForm = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade edit-file',
    id: 'edit-file',
    attributes: {
        "tabindex":"-1",
        "role":"dialog",
        "aria-labelledby":"file-edit",
        "aria-hidden":"true"
    },
    template: JST["events/events-files-form.hbs"],

    previousAttibutes: {},

    events: {
        'click .file-save': 'save',
        'click .file-cancel': 'cancel',
        'keyup :input#file-name':  "nameChanged",
        'keyup :input#file-url':  "urlChanged",
        'keyup :input#file-order':  "orderChanged"
    },

    initialize: function() {
        _.bindAll(this, 'nameChanged');
        _.bindAll(this, 'urlChanged');
        _.bindAll(this, 'orderChanged');
        this.previousAttibutes = this.model.toJSON();
    },

    nameChanged: function() {
        tmw.validationClear('#form-file', '#file-name');
        var input = this.$('#file-name');
        this.model.set({name: input.val()}, {validate:true});
    },

    urlChanged: function() {
        tmw.validationClear('#form-file', '#file-url');
        var input = this.$('#file-url');
        this.model.set({url: input.val()}, {validate:true});
    },

    orderChanged: function() {
        tmw.validationClear('#form-file', '#file-order');
        var input = this.$('#file-order');
        this.model.set({order: input.val()}, {validate:true});
    },

    cancel: function() {
        if(this.model.changedAttributes()) {
            if(confirm("El grupo se ha modificado, estás seguro de que no quieres guardar los cambios?")) {
                if(this.model.isNew()){
                    this.model.destroy();
                }
                else {
                    this.model.set(this.previousAttibutes);
                }
                this.$el.modal('hide');
            }
        }
        else {
            if(this.model.isNew()){
                this.model.destroy();
            }
            this.$el.modal('hide');
        }

        this.$el.modal('hide');
    },

    render: function() {

        var self = this;
        var data = this.model.toJSON();

        this.$el.html(this.template(data));
        // modal
        this.$el.modal('show')
            .on('hidden.bs.modal',function() {
                self.undelegateEvents();
                self.$el.removeData().unbind().remove();
            });

        this.$el.keypress(function(event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode === 13) {
                self.save();
            }
        });

        $('#main').append(this.el);

        return this;
    },

    /**
     * Guardamos primero el modelo con los datos básicos del file
     * Una vez creado el modelo actulizamos la imagen
     * @return {[type]} [description]
     */
    save: function() {

        var that = this;

        var data = {
            name: $('#file-name').val(),
            order: $('#file-order').val(),
            file: $('#file-file').val()
        };

        // clear errores previos
        tmw.validationClear('#form-file');

        var current = '';
        if ($('#file-current-file').length) {
            current = $('#file-current-file').val();
        }

        if (data.file === '' && current === '') {
            return notify.error('No has seleccionado ninguna imagen', true);
        }

        this.uploadFile(current, function(file) {
            if (file) {
                data.file = file;
                that.model.save(data, {
                    success: function() {
                        notify.success('El file ha sido guardado', true);
                        that.$el.modal('hide');
                    },

                    error: function() {
                        notify.error('Ha sucedido algún error', true);
                    }
                }, {validate: true});
            } else {
                notify.error('ERROR: no se ha podido cargar la imagen', true);
            }
        });
    },

    // callback acepta 1 parámetro: el nombre del archivo (string) cargado o false (bool) si error
    uploadFile: function(current, callback) {

        var event_id = $('#_event_id').val();

        // si actualmente hay una imagen la devolvemos tal cual
        if (current) {
            return callback(current);
        }

        $('#file-form').ajaxSubmit({
            type: 'POST',

            data: {prefix: event_id},

            beforeSubmit: function() {
                $('#upload-progress-container').removeClass('hide');
                $('#file-upload-info').addClass('hide');
            },

            uploadProgress: function(event, position, total, percentComplete) {
                $('#file-upload-progress').css('width', percentComplete + '%');
                if (percentComplete === 100) {
                    $('#file-upload-info').removeClass('hide');
                    $('#upload-progress-container').addClass('hide');
                }
            },

            complete: function() {
                $('#upload-progress-container').addClass('hide');
                $('#file-upload-info').addClass('hide');
            },

            success: function(upload) {
                callback(upload.filename);
            },

            error: function(xhr) {
                console.error(xhr);
                callback(false);
            }
        });
    }
});
