window.EventsAddNew = Backbone.View.extend({
    tagName: 'div',
    className: 'modal fade edit-sponsor',
    id: 'edit-sponsor',
    attributes: {
        "tabindex":"-1",
        "role":"dialog",
        "aria-labelledby":"sponsor-edit",
        "aria-hidden":"true"
    },
    template: JST['events/events-add-new.hbs'],
    autoslug: true,

    events: {
        'keyup #title': 'changeTitle',
        'keyup #slug': 'changeSlug',
        'click #parse-title': 'parseTitle',
        'click #btn-save': 'save'
    },

    initialize: function() {
        this.model = new EventsModel();
        this.collection.add(this.model);
    },

    render: function() {
        this.$el.html(this.template());
        this.$el.modal();
    },

    close: function() {
        var that = this;
        this.$el.modal('hide');
        this.$el.on('hidden.bs.modal', function() {
            that.remove();
        });
    },

    save: function() {
        var that = this;
        this.model.save(null, {
            success: function(model) {
                app.navigate('admin/events/edit/'+ model.get('_id'),true);
                var eventsForm = new EventsForm({model: model});
                var data = model.toJSON();
                $('#main').html(eventsForm.render(data).el);
                $('#page-title').text('Editar evento');
                tmw.events.init(data);

                that.close();
            },
            error: function(xhr) {
                console.error(xhr);
                alert('Error al crear evento (ver consola)');
            }
        });
    },

    changeTitle: function(e) {
        var $el = $(e.currentTarget);
        var title = $el.val();

        this.model.set('name', title);

        if (this.autoslug) {
            var slug = tmw.slugs.parse(title);
            this.model.set('slug', slug);
            this.$el.find('#slug').val(slug);
        }
    },

    changeSlug: function(e) {
        this.autoslug = false;
    },

    parseTitle: function() {
        var title = this.$el.find('#title').val();
        var slug = tmw.slugs.parse(title);
        this.model.set('slug', slug);
        this.$el.find('#slug').val(slug);
    }
});

