window.UserStatistics = Backbone.View.extend({

    template: JST["events/user-statistics.hbs"],

    events: {
        'click #add-status-history': 'add_status_history',
        'click .user-status-history-remove': 'remove_status_history'
    },

    // STATUS HISTORY
    add_status_history: function() {

        var data = {
            timestamp: $('#status-history-timestamp').val(),
            source: $('#status-history-source option:selected').val(),
            show: $('#status-history-show option:selected').val(),
            desc: $('#status-history-desc').val()
        };

        var that = this;

        this.model = new EventsUsersListItemView(this.model);
        console.log('inside item', this);

        var arr = this.model.get('status_history');
        arr.push(data);
        this.model.set('status_history', arr);
        this.model.save(null,{
            success: function(m) {
                that.status_history_generate();
                $('#status-history-timestamp').val('');
                $('#status-history-desc').val('');
            }
        });

    },

    remove_status_history: function(e) {
        var that = this;
        bootbox.confirm('Eliminar elemento?', function(result) {
            if (result) {
                var index = $(e.target).closest('li').attr('data-index');
                that.model.status_history.splice(e.target.parentNode.parentNode.getAttribute('data-index'), 1);
                that.status_history_generate();
            }
        });
    },

    status_history_generate: function() {
        var data = {
            status_history: this.model.status_history
        };
        var status_history = JST['users/users-status-history.hbs'](data);
        $('#user-status-history-list').empty().append(status_history);
        this.model.save();
    },

    initialize: function(model) {

        this.model = model.model;

        console.log('initialize', this.model);

        // var loaderInterval = setInterval(function() {
        //     $('#loader').html($('#loader').html() + '.');
        // }, 66);

        // this.model.event_id = $('#event-id').val();
        // var eventUserStatistics = new EventUserStatistics({model: this.model});

        // console.log('eventUserStatistics', eventUserStatistics);



        // $.get('http://62bbe7ed872d806fdadc-3ac572f76f05cafbcf7a23f9707c72df.r51.cf3.rackcdn.com/114247/620/535695086911650d5ecef4eb_1400517539217', function(data) {

        //     self.map = L.map('map', {
        //         measureControl: true
        //     }).setView([32.526042, -4.95921], 7);
        //     L.tileLayer(tmw.config.maps, {
        //             attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        //         }).addTo(self.map);

        //     self.latLng = [];
        //     self.chart = [];


        //     var _arr = [];

        //     for (var i in data) {
        //         _arr = data[i];
        //     }

        //     window.globalData = _arr;

        //     for (var i = 0, len = _arr.length; i < len; ++i) {
        //         self.latLng.push([_arr[i].loc[1], _arr[i].loc[0]]);
        //         self.chart.push([_arr[i].gps, _arr[i].bat]);
        //     }

        //     self.polyline = new L.polyline(self.latLng, {
        //         color: '#333',
        //         smoothFactor: 1
        //     });

        //     self.polyline.addTo(self.map);



        //     var detailOptions = {
        //         series: {
        //             lines: {
        //                 show: true,
        //                 lineWidth: 3
        //             },
        //             shadowSize: 0
        //         },
        //         grid: {
        //             hoverable: true,
        //             backgroundColor: {
        //                 colors: ["#96CBFF", "#75BAFF"]
        //             }
        //         },
        //         yaxis: {
        //             color: "#8400FF"
        //         },
        //         xaxis: {
        //             mode: "time",
        //             color: "#8400FF"
        //         },
        //         selection: {
        //             mode: "x"
        //         }
        //     };

        //     var masterOptions = {
        //         series: {
        //             lines: {
        //                 show: true,
        //                 lineWidth: 3
        //             },
        //             shadowSize: 0
        //         },
        //         grid: {
        //             backgroundColor: {
        //                 colors: ["#96CBFF", "#75BAFF"]
        //             }
        //         },
        //         yaxis: {
        //             color: "#FAF9CF"
        //         },
        //         xaxis: {
        //             mode: "time",
        //             color: "#8400FF"
        //         },
        //         selection: {
        //             mode: "x"
        //         }
        //     };

        //     var dataDetail = [{
        //             label: "Bateria",
        //             data: self.chart,
        //             color: "#FF7575"
        //         }];

        //     var plotDetail = $.plot($("#plot-container #detailContainer"),
        //     dataDetail,
        //     detailOptions
        //     );

        //     var plotMaster = $.plot($("#plot-container #masterContainer"), [{
        //             data: self.chart,
        //             color: "#FF7575"
        //         }],
        //     masterOptions
        //     );

        //     $("#plot-container #detailContainer").bind("plotselected", function(event, ranges) {


        //         var getFormatTime = function(timestamp) {
        //             var date = new Date(timestamp);
        //             var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        //             var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        //             var seconds = date.getSeconds() < 10 ? 0 + date.getSeconds() : date.getSeconds();
        //             return date.getHours() - 1 + ':' + date.getMinutes() + ':' + date.getSeconds();
        //         };


        //         self.map.removeLayer(self.polyline);
        //         if (self.markercluster) {
        //             $('#show-markercluster').prop('checked', false);
        //             self.map.removeLayer(self.markercluster);
        //             self.markercluster = undefined;
        //         }

        //         self.latLng = [];
        //         self.chart = [];

        //         var minRange, maxRange;
        //         for (var i = 0, len = _arr.length; i < len; ++i) {
        //             if (_arr[i].gps >= ranges.xaxis.from && _arr[i].gps <= ranges.xaxis.to) {
        //                 self.latLng.push([_arr[i].loc[1], _arr[i].loc[0]]);
        //                 self.chart.push([_arr[i].gps, _arr[i].bat]);
        //                 if (!minRange)
        //                     minRange = _arr[i];
        //                 maxRange = _arr[i];
        //             }
        //         }

        //         self.polyline = new L.polyline(self.latLng, {
        //             color: '#333',
        //             smoothFactor: 1
        //         });


        //         self.polyline.addTo(self.map);

        //         var elapsedTime = new Date((new Date(maxRange.gps).getTime() - new Date(minRange.gps).getTime()));
        //         $('#elapsed-time').html('Elapsed time: ' + getFormatTime(elapsedTime));

        //         plotDetail = $.plot($("#plot-container #detailContainer"), dataDetail,
        //         $.extend(true, {}, detailOptions, {
        //             xaxis: {
        //                 min: ranges.xaxis.from,
        //                 max: ranges.xaxis.to
        //             }
        //         }));

        //         plotMaster.setSelection(ranges, true);
        //     });

        //     $("#plot-container #masterContainer").bind("plotselected", function(event, ranges) {
        //         plotDetail.setSelection(ranges);
        //     });
        //     $('#plot-container #masterContainer, #plot-container #detailContainer').bind('dblclick', function(ev, ranges) {


        //         self.map.removeLayer(self.polyline);
        //         if (self.markercluster) {
        //             $('#show-markercluster').prop('checked', false);
        //             self.map.removeLayer(self.markercluster);
        //             self.markercluster = undefined;
        //         }

        //         self.latLng = [];
        //         self.chart = [];


        //         var _arr = [];

        //         for (var i in data) {
        //             _arr = data[i];
        //         }

        //         for (var i = 0, len = _arr.length; i < len; ++i) {
        //             self.latLng.push([_arr[i].loc[1], _arr[i].loc[0]]);
        //             self.chart.push([_arr[i].gps, _arr[i].bat]);
        //         }

        //         self.polyline = new L.polyline(self.latLng, {
        //             color: '#333',
        //             smoothFactor: 1
        //         });

        //         $('#elapsed-time').html('');

        //         self.polyline.addTo(self.map);

        //         if (ranges)
        //             plotDetail.setSelection(ranges);

        //     });

        //     $('#loader').hide();
        //     clearInterval(loaderInterval);

        // });
    },

    render: function() {

        // var data = this.model.toJSON();
        this.$el.html(this.template(this.model));

        var data = {
            status_history: this.model.status_history
        };

        var status_history = JST['users/users-status-history.hbs'](data);

        // $('#user-status-history-list').empty().append(status_history);
        this.$el.find('#user-status-history-list').append(status_history);

        return this;


    }
});
