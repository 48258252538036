window.AttachFileToTrackFormView = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade',
    id: 'modal-media-form',
  //  attributes: 'tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"',
    template: JST['track-attach-file'],

    mediaItemn: null,
    previousAttributes: {},

    initialize: function() {
        console.log("inicializando");
        if (!this.model) {
            this.model = new TrackModel();
        }
        this.previousAttributes = this.model.toJSON();
    },

    events: {
        'click #save-track': 'save',
        'click #cancel-track': 'cancel'
    },

    cancel: function() {
        this.$el.modal('hide');
        // TODO meter un remove en el evento de on hide
    },

    save: function(e) {

        console.log('save!');

        if (e) e.preventDefault();
        var that = this;
        var file = $('#track-file').val();

        if (file === '') {
            return bootbox.alert('Selecciona un archivo');
        }

        $('#save-track').addClass('btn-loading');

        $('#form-track-attach').ajaxSubmit({
            url: '/api/tracks/'+that.model.get('_id')+'/attach',
            type: 'post',
            dataType: 'json',
            success: function(data) {

                bootbox.alert('Archivo actualizado.', function() {
                    that.$el.modal('hide');
                });
                $('#save-track').removeClass('btn-loading');
            },
            error: function() {
                bootbox.alert('Ha sucedido un error al cargar el archivo. Por favor, inténtalo de nuevo.');
                $('#save-track').removeClass('btn-loading');
            }
        });
    },

    render: function(data) {

        console.log("Render");
        data = data || this.model.toJSON();
        this.previousAttributes = data;

        this.$el.html(this.template(data));
        this.$el.modal({
            backdrop: 'static'
        });

        return this;
    }
});
