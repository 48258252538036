window.EventsMediaListItemView = Backbone.View.extend({

    tagName: 'tr',
    className: 'col-md-3',
    template: JST['events/events-media-list-item.hbs'],

    events: {
        "click .media-edit": "editItem",
        "click .media-delete": "deleteItem"
    },

    initialize: function() {
        this.model.bind('change', this.render, this);
        this.model.bind('remove', this.remove, this);
    },

    remove: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.$el.remove();
    },

    deleteItem: function(e) {
        var that = this;
        var remove = function() {
            that.model.destroy({
                success: function () {
                    notify.info('Media eliminado.', true);
                    that.undelegateEvents();
                    that.$el.removeData().unbind();
                    that.$el.remove();
                },
                error: function() {
                    bootbox.alert('Ha ocurrido un error, por favor, inténtalo de nuevo.');
                }
            });
        };

        if (e.ctrlKey) {
            return remove();
        }

        return bootbox.confirm("Seguro que deseas eliminar este media?", function(result) {
            if(result) {
                remove();
            }
        });
    },

    editItem: function() {
        new EventsSessionForm({model:this.model}).render();
    },

    render:function () {
        var data = this.model.toJSON();
        this.$el.html(this.template(data));
        return this;
    }
});
