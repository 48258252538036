window.TrackerReturnsView = Backbone.View.extend({

    template: JST["returns"],

    tagName: 'div',

    className: 'modal fade trackerReturns',

    attributes: {
        'tabindex':'-1',
        'role':'dialog',
        'aria-labelledby':'viewTrackers',
        'aria-hidden':'true'
    },

    events: {

    },

    initialize: function() {
        this.collection.on('sync',this.tracker_updated,this);
    },

    tracker_updated: function() {
        this.$el.find('.typeahead').typeahead('setQuery', '');
        traky.flash.success('Ok, actualizado',true,2);
        this.unblock();
        this.$el.find('input').select();
    },

    block: function() {
        this.$el.find('input').attr('disabled',true);
    },
    unblock: function() {
        this.$el.find('input').attr('disabled',false);
    },

    update_tracker: function(datum) {
        this.block();
        var location = this.$el.find("input[name='location']:checked").val();
        var tracker = this.collection.get(datum._id);
        if(!tracker) {

            tracker = new TrackersModel(
                {
                    _id: datum._id,
                    location: location
                }
            );

            this.collection.create(tracker,{wait: true});
        }
    },

    remove: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
    },

    render: function() {

        this.$el.html(this.template(this.model));

        var that = this;

        this.typeahead = this.$('.typeahead')
            .keypress(function(event) {
                var keycode = (event.keyCode ? event.keyCode : event.which);
                if (keycode === 13) {
                    var suggestion = that.$('input.typeahead').data().ttView.dropdownView.getFirstSuggestion();
                    if(suggestion) that.update_tracker(suggestion.datum);
                    else that.$('.typeahead').typeahead('setQuery', '');
                }
            })
            .typeahead('destroy') // prefetch!
        .typeahead({
            //name: 'trackers',
            prefetch: '/api/trackers/returns',
            ttl: 1,
            valueKey: 'name',
            limit: 15
        }).on('typeahead:selected', function(e, datum) {
            that.update_tracker(datum);
        });

        return this;
    }

});
