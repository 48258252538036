window.TrackerAssignWizard = Backbone.View.extend({

    template: JST["assign-wizard"],

    tagName: 'div',

    _event_id: null,
    _event: null,
    selected_tracker: {},
    selected_user: {},

    className: 'modal fade assignWizard',

    attributes: {
        'tabindex':'-1',
        'role':'dialog',
        'aria-labelledby':'viewTrackers',
        'aria-hidden':'true'
    },

    events: {
        'click #btn-wizzard-assign':'assign_tracker_to_user'
    },

    initialize: function(options) {
        // this.collection.on('sync',this.tracker_updated,this);
        console.log("initialize assingn-witzard options event id: "+options._event);
        this._event_id = options._event || null;
        this._event = app.events.get(this._event_id);
        if (!this._event) this._event = {};
    },

    assign_tracker_to_user: function() {

        var that = this;

        var user_id = this.selected_user._id;
        var tracker_id = this.selected_tracker._id;
        console.log("assign_tracker_to_user user id:"+user_id+" tracker_id:"+tracker_id);
        if (!user_id || !tracker_id) {
            return traky.flash.error('Indica dorsal y tracker.');
        }

        this.block();

        if (this.tracker_has_user(tracker_id)) {
            this.unblock();
            traky.flash.error('Este tracker ya está en uso para este evento. ¿multi-tracker?');
            return;
        }

        if (this.user_has_tracker(user_id, tracker_id)) {
            this.unblock();
            traky.flash.error('Este usuario ya tiene este tracker asignado.');
            return;
        }

        var url = "/api/events/"+this._event_id+"/users/"+user_id+"/trackers/"+tracker_id;
        var url = "/api/events/"+this._event_id+"/users/"+user_id+"/trackers/"+tracker_id;
        console.log("Ejecutando: " + url);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {},
            success: function() {
                that.assign_completed(user_id, tracker_id);
            },
            error: function() {
                bootbox.alert('Error al ejecutar la acción');
            }
        });
    },

    assign_completed: function(user_id, tracker_id) {
        console.log("assign_completed user_id:"+user_id+" tracker_id:"+tracker_id);
        var dorsal = app.eventUsersCollection.get(user_id);
        var tracker = app.eventTrackersCollection.get(tracker_id);

        this.selected_tracker = {};
        this.selected_user = {};
        this.$('.typeahead').typeahead('setQuery', '');
        traky.flash.success('Ok, actualizado',true,2);
        this.show_last(dorsal, tracker);
        this.unblock();
        this.$('#users-typeahead').focus();
    },

    // verifica que el track no esté asignado a ningún user
    tracker_has_user: function(tracker_id) {
      console.log("tracker_has_user");
        var users = app.eventUsersCollection.toJSON();
        var allow_multiple = $('#allow-multiple-tracks').is(':checked');

        for (var j=0, jlen=users.length; j<jlen; j++) {
            var user = users[j];
            if (user && user.hasOwnProperty('trackers') && user.trackers.length) {
                for (var i=0, len=user.trackers.length; i<len; i++) {
                    var tracker = user.trackers[i];
                    if (tracker._id === tracker_id && allow_multiple === false) {
                        return true;
                    }
                }
            }
        }

        return false;
    },

    // verifica que el usuario no tenga asignado ningún tracker
    user_has_tracker: function(user_id, tracker_id) {
      console.log("user_has_tracker");
        var user = app.eventUsersCollection.get(user_id);
        if (!user) return false;

        var trackers = user.get('trackers');
        for (var i=0, len=trackers.length; i<len; i++) {
            var tracker = trackers[i];
            if (tracker._id === tracker_id) {
                return true;
            }
        }

        return false;
    },

    block: function() {
        this.$el.find('input:not(:checkbox)').attr('disabled',true);
    },

    unblock: function() {
        this.$el.find('input:not(:checkbox)').attr('disabled',false);
        this.$('#users-typeahead').focus();
    },

    remove: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
    },

    /**
     * Solo se podrá mostrar el track añadido en caso de que previamente existiera
     * en la coleccion `app.eventTrackersCollection`. Para ello hay que añadir los
     * trackers en la sección de trackers antes de hacer la asignación al
     * participante.
     *
     * @param  {[type]} dorsal  [description]
     * @param  {[type]} tracker [description]
     * @return {[type]}         [description]
     */
    show_last: function(dorsal, tracker) {
        if (!dorsal || !tracker) return;
        this.$el.find('#last-added-dorsal').html(dorsal.get('info').dorsal);
        this.$el.find('#last-added-tracker').html(tracker.get('name'));
        this.$el.find("#last-added").removeClass('hide');
    },

    render: function() {
        var that = this;

        this.$el.html(this.template(this.model));

        this.typeahead = this.$('#trackers-typeahead')
            .keypress(function(event) {
                var keycode = (event.keyCode ? event.keyCode : event.which);
                if (keycode === 13) {
                    var suggestion = that.$('#trackers-typeahead').data().ttView.dropdownView.getFirstSuggestion();
                    if(suggestion) {
                        console.log('last_track', datum);
                        that.selected_tracker = suggestion.datum;
                        that.$('#btn-wizzard-assign').focus();
                    }
                    else that.$('.typeahead').typeahead('setQuery', '');

                    console.log(" selected tracker"+that.selected_tracker);
                }
            })
            .typeahead('destroy') // prefetch!
        .typeahead({
            name: 'trackers',
            prefetch: '/api/trackers/returns',
            ttl: 1,
            valueKey: 'name',
            limit: 15
        }).on('typeahead:selected', function(e, datum) {
            that.selected_tracker = datum;
            that.$('#btn-wizzard-assign').focus();
        });

        // Typeahead de users

        this.typeahead = this.$('#users-typeahead')
            .keypress(function(event) {
              console.log("typeahead keypress");
                var keycode = (event.keyCode ? event.keyCode : event.which);
                if (keycode === 13) {
                    var suggestion = that.$('#users-typeahead').data().ttView.dropdownView.getFirstSuggestion();
                    if(suggestion) {
                        that.selected_user = suggestion.datum;
                        console.log("suggestion selected_user:"+  that.selected_user);
                        that.$('#trackers-typeahead').focus();
                    }
                    else {
                      that.$('.typeahead').typeahead('setQuery', '');
                    }

                }
                  console.log("selected user"+that.selected_user);
            })
            .typeahead('destroy') // prefetch!
        .typeahead({
            //name: 'users',
            prefetch: '/api/events/' + this._event_id + '/users?format=typeahead',
            prefetch: '/api/events/' + this._event_id + '/users?format=typeahead',
            //prefetch: '/api/events/53c0457ca77f00c9398b4567/users?format=typeahead',
            ttl: 1,
            valueKey: 'name',
            limit: 15
        }).on('typeahead:selected', function(e, datum) {
            that.selected_user = datum;
            that.$('#trackers-typeahead').focus();
            console.log(that.selected_user);
        });

        return this;
    }

});
