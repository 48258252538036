window.EventsSponsorsListView = Backbone.View.extend({

    tagName: 'div',
    className: 'panel panel-default',
    template: JST["events-sponsors-list"],
    events: {
        'click #sponsor-add': 'addSponsor'
    },

    initialize: function() {
    //    _(this).bindAll('add');
    //    this.collection.bind('add', this.add);
      this.listenTo(this.collection, 'add', this.add);
    },

    add: function(sponsor) {
        var render = new EventsSponsorsListItemView({model:sponsor}).render().el;
        $(this.el).find('table tbody').append(render);
    },

    addSponsor: function() {
        var sponsor = new EventSponsor();
        this.collection.add(sponsor);
        new EventsSponsorForm({model:sponsor}).render();
    },

    render: function() {
        this.$el.html(this.template());
        return this;
    }
});
