window.EventsSessionsListItemView = Backbone.View.extend({

    tagName: 'tr',
    template: JST['events-sessions-list-item'],

    events: {
        "click .session-edit": "editItem",
        "click .session-delete": "deleteItem"
    },

    initialize: function() {
        this.model.bind('change', this.render, this);
        this.model.bind('remove', this.remove, this);
    },

    deleteItem: function(e) {
        var self = this;
        var remove = function() {
            self.model.destroy({
                success: function () {
                    notify.info('Session eliminado.', true);
                    self.undelegateEvents();
                    self.$el.removeData().unbind();
                    self.$el.remove();
                },
                error: function() {
                    bootbox.alert('Ha ocurrido un error, por favor, inténtalo de nuevo.');
                }
            });
        };

        if (e.ctrlKey) {
            return remove();
        }

        return bootbox.confirm("Seguro que deseas eliminar este session?", function(result) {
            if(result) {
                remove();
            }
        });
    },

    editItem: function() {
        new EventsSessionForm({model:this.model}).render();
    },

    render:function () {
        var data = this.model.toJSON();
        data.cdn_url = (data.source==="socket") ? false : true;
        this.$el.html(this.template(data));
        return this;
    }
});
