window.EventsListView = Backbone.View.extend({

  tagName: 'div',
  className: 'trackersList',
  template: JST["events-list"],

    events: {
        'click #all-events': 'events_check_all',
        'click #btn-create-event': 'createEvent',
        'click #remove-filter': 'remove_filter',
        'click #events_do_action': 'events_do_action'
    },

    actions: [{
        name: '[Beta] Fill users2',
        url: '/api/events/actions/fillusers2'
    }, {
        name: '[Beta] Fill users',
        url: '/api/events/actions/fillusers'
    }, {
        name: '[Beta] Auto users2',
        url: '/api/events/actions/autousers'
    }, {
        name: '[Beta] Auto users2 reset',
        url: '/api/events/actions/resetautousers'
    }, {
        name: '[Beta] Auto Trackers To Users',
        url: '/api/events/actions/trackerstousers'
    }],



    initialize: function() {
      /*  _(this).bindAll('add', 'remove');
        this.collection.bind('add', this.add);
        this.collection.bind('remove', this.remove);
        */
          this.listenTo(this.collection, 'add', this.add);
          this.listenTo(this.collection, 'remove', this.remove);
    },



    add: function(event) {
        $('#eventsLoading').remove();
        $(this.el).find('table tbody').append(new EventsListItemView({
            model: event
        }).render().el);
    },

    remove: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
    },

    render: function() {

        if (!traky.checkLogin()) {
            return false;
        }

        var actions = this.actions;

        var data = {
            events: traky.events.available,
            actions: actions
        };

        this.$el.html(this.template(data));
        traky.events.dataTable('events-datatable');
        return this;
    },

    remove_filter: function() {
        var oTable = traky.events.table;
        oTable.fnFilter('');
        $('#number-events-text').text($('#events-datatable_info').text());
    },

    events_get_selected: function() {
        var events = [];

        $('.eventsList tr').each(function() {
            if ($(this).find('.checkbox').is(':checked')) {
                events.push($(this).find('.checkbox').data('id'));
            }
        });

        return events;
    },

    events_do_action: function() {

        var ids = this.events_get_selected();

        if (ids.length) {

            var events = [];
            _.each(ids, function(model_id) {
                var event = app.eventsList.get(model_id).toJSON();
                events.push(event._id);
            });

            console.log(events);

            var url = $('#events-actions').val();

            console.log(url);

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    events: events
                },
                success: function() {
                    notify.success('La acción se ha ejecutado', true);
                },
                error: function() {
                    notify.error('Error al ejecutar la acción', true);
                }
            });

        } else {
            bootbox.alert('No has seleccionado eventos.');
        }
    },

    events_check_all: function(e) {
        var oTable = $('#events-datatable').dataTable();
        if ($('#all-events').prop('checked')) {
            $(oTable.fnGetNodes()).find(':checkbox').each(function () {
                $(this).prop('checked', true);
            });
        } else {
            $(oTable.fnGetNodes()).find(':checkbox').each(function () {
                $(this).prop('checked', false);
            });
        }
    },

    createEvent: function() {
      console.log("crear evennto");
        app.navigate('#admin/events/add',true);
      /*  var addForm = new EventsAddNew({
            collection: this.collection
        });
        addForm.render();
*/
    }
});
