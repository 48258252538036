window.EventSettingsForm = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade',
    id: 'modal-event-settings',
    attributes: ' tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"',
    template: JST['events/events-settings-form.hbs'],

    events: {
        'click #btn-save-settings': 'save',
        'click #btn-add-alias': 'addAlias',
        'click .btn-remove-alias': 'removeAlias'
    },

    initialize: function() {
        this.alias = this.model.get('alias');
    },

    render: function() {
        var data = this.model.toJSON();
        this.$el.html(this.template(data));

        this.setupModal();
        this.renderAlias();

        return this;
    },

    setupModal: function() {
        var that = this;
        this.$el.modal();
        this.$el.on('hidden.bs.modal', function() {
            that.remove();
        });
    },

    addAlias: function() {

        var alias = {
            host: this.$el.find('#alias-host').val(),
            lang: this.$el.find('#alias-lang').val()
        };

        var find = _.findWhere(this.alias, alias);
        if (!find) {
            this.alias.push(alias);
            this.renderAlias();
        } else {
            notify.error('Este alias ya existe', true);
        }
    },

    renderAlias: function() {
        var that = this;
        var tpl = _.template(this.$el.find('#alias-row').html());

        this.$el.find('#alias-list').empty();
        this.alias.forEach(function(alias) {
            that.$el.find('#alias-list').append(tpl(alias));
        });
    },

    removeAlias: function(e) {
        $e = $(e.currentTarget);
        var host = $e.data('host');
        var lang = $e.data('alias');
        console.log(host, lang);
        var newAlias = [];


        this.alias.forEach(function(alias) {
            if (alias.host !== host && alias.lang !== lang) {
                newAlias.push(alias);
            }
        });

        this.alias = newAlias;

        this.renderAlias();
    },


    save: function() {

        var settings = this.model.get('settings') || {};

        settings.map_type = $('#map_type').val();
        settings.map_start_position = $('#map_start_position').val();
        settings.map_start_zoom = $('#map_start_zoom').val();
        settings.tmw_unidades = $('#tmw_unidades').val();
        settings.zoomcontrol = $('#zoomcontrol').val();
        settings.participantes = $('#participantes').val();
        settings.spot_logo = $('#spot_logo').val();
        settings.mostrar_track = $('#mostrar_track').val();
        settings.mostrar_chat = $('#mostrar_chat').val();
        settings.mostrar_staff = $('#mostrar_staff').val();
        settings.mostrar_soporte = $('#mostrar_soporte').val();
        settings.lang = $('#lang').val();
        settings.check_map_image = $('#check_map_image').val();
      //  settings.google_noindex = $('#google_noindex').val();
      // lp ponemos a saco mirar porque pone on
        settings.google_noindex =  false;
        settings.has_registro = $('#has_registro').val();
        //settings.has_dorsales = $('#has_dorsales').val();
        settings.has_dorsales =  $('#has_dorsales').is(':checked');
        settings.has_sprites = $('#has_sprites').val();
        settings.gifts = $('#gifts').val();
        settings.iframe_info = $('#iframe_info').val();
        settings.flood_comments = $('#flood_comments').val();
        settings.custom_html = $('#custom_html').val();
        settings.facebook = $('#facebook').val();
        settings.facebook_page = $('#facebook_page').val();
        settings.facebook_page_msg = $('#facebook_page_msg').val();
        settings.facebook_page_img = $('#facebook_page_img').val();
        settings.social_url = $('#social_url').val();
        settings.twitter = $('#twitter').val();
        settings.feed_rss = $('#feed_rss').val();
        settings.adventure_logo = $('#adventure_logo').val();
        settings.adventure_link = $('#adventure_link').val();
        settings.header_image = $('#header_image').val();
        settings.header_mov_image = $('#header_mov_image').val();
        settings.header_color = $('#header_color').val().replace('#', '');
        settings.header_size = $('#header_size').val();
        settings.sidebar_color = $('#sidebar_color').val().replace('#', '');
        settings.sidebar_text_color = $('#sidebar_text_color').val().replace('#', '');
        settings.border_color = $('#border_color').val().replace('#', '');
        settings.media_bar = $('#media_bar').val();
        settings.mediabar_order = $('#mediabar_order').val();
        settings.mediabar_show = $('#mediabar_show').val();
        settings.comment_header = $('#comment_header').val();
        settings.user_display_text = $('#user_display_text').val();
        settings.sidebar_patrocinadores = $('#sidebar_patrocinadores').val();
        settings.sidebar = $('#sidebar').val();
        settings.template = $('#template').val();
        settings.canales_chat = $('#canales_chat').val();
        settings.rightclick = $('#rightclick').val();
        settings['private'] = $('#private').val();

        this.model.set('settings', settings);
        this.model.set('alias', this.alias);

        notify.info('Guarda el evento para que la nueva configuración tenga efecto.', true);
        this.$el.modal('hide');
    }

});
