window.TrackSectionListItem = Backbone.View.extend({

    tagName: 'p',
    template: JST["track-section-list-item"],

    events: {
        "click .edit-section": "editItem",
        "click .remove-section": "deleteItem",
        'dblclick td[data-inline-edit]': traky.inlineEdit,
        'click .btn-popover': 'show_popover'
    },

    deleteItem: function(ev) {

        var self = this;

        bootbox.confirm("Seguro que deseas eliminar este section?", function(result) {
            if (result) {
                self.$el.fadeOut('fast', function() {
                    $.ajax({
                        url: '/api/tracks/' + self.model.attributes.track_id + '/section/' + self.model.id,
                        type: 'DELETE',
                        success: function(result) {
                            notify.info('Section eliminado.', true);
                            self.remove();
                        },
                        error: function() {
                            self.$el.fadeIn();
                            bootbox.alert('Ha ocurrido un error, por favor, inténtalo de nuevo.');
                        }
                    });
                });
            }
        });

    },

    editItem: function(event) {
        event.preventDefault();

        $('#section-name').val(this.model.attributes.name);
        $('#section-color').val(this.model.attributes.color);
        $('#section-show-on-graph').prop('checked',this.model.attributes.show_on_graph);
        $('#section-show-on-map').prop('checked',this.model.attributes.show_on_map);
        $('#section-location-start').val(this.model.attributes.location_start);
        $('#section-location-end').val(this.model.attributes.location_end);
        $('#track-index-start').val(this.model.attributes.track_index_start);
        $('#track-index-stop').val(this.model.attributes.track_index_stop);

        $('#section-index-type option[value="' + this.model.attributes.index_type + '"]').prop('selected',true);

        $('#section-tags').val(this.model.attributes.tags);

        $('#sections-modal').data('sectionid', this.model.id);

    },

    show_popover: function() {


    },

    render: function() {
        if (!traky.checkLogin()) {
            return false;
        }
        var data = this.model;
        // console.log('data ', data.toJSON())
        console.log('model', this.model)
        this.$el.html(this.template(data.toJSON()));
        return this;
    }
});
