window.TrackersListItemView = Backbone.View.extend({

    tagName: 'tr',
    template: JST["trackers-list-item"],

    events: {
        "click .tracker-edit": "editItem",
        "click .tracker-remove": "deleteItem",
        "click .tracker-refresh": "refreshItem",
        'click td[data-inline-edit]': traky.inlineEdit
    },

    delete: function() {
        var self = this;
        this.model.destroy({
            success: function () {
                notify.info('El tracker se ha eliminado.', true);
                self.remove();

            },
            error: function() {
                bootbox.alert('Ha ocurrido un error, por favor, inténtalo de nuevo.');
            }
        });
    },

    deleteItem: function(e) {
        var self = this;

        if (e.ctrlKey && e.altKey) {
            // ctrl+click
            this.delete();
        } else {
            bootbox.confirm("Seguro que deseas eliminar el tracker?", function(result) {
                if(result) {
                    self.delete();
                }
            });
        }
    },

    editItem: function(event) {
        event.preventDefault();
    
        app.navigate('admin/trackers/edit/'+ this.model.get('_id'), true);
        return false;
        this.TrackersForm = new TrackersForm({model: this.model});
        var data = this.model.toJSON();
        $('#main').html(this.TrackersForm.render(data).el);
        $('#page-title').text('Editar tracker');
    },

  /*  refreshItem: function() {
        var self = this;
        var data = this.model.toJSON();
        data.gps_location = "";
        if(data.latest_position) {
            data.elapsed_time = time_ago(data.latest_position.server_timestamp/1000,2);
            data.gps_location += parseFloat(data.latest_position.location[1]).toFixed(2) + "," +  parseFloat(data.latest_position.location[0]).toFixed(2);
        }
        this.$el.html(this.template(data));
        return this;
    },

    updateItem: function(item) {
        item.$el.html(this.template(data));
        return this;
    },
*/
    render: function () {

        var data = this.model.toJSON();

        data.gps_location = "";
        if(data.latest_position) {
            data.elapsed_time = time_ago(data.latest_position.server_timestamp/1000,2);
            data.gps_location += parseFloat(data.latest_position.location[1]).toFixed(2) + "," +  parseFloat(data.latest_position.location[0]).toFixed(2);
        }
        if(data.renew_date) {
            data.renew_date_format = time_ago(data.renew_date/1000,2);
        }
    //  console.log("TRACKER LIST ITEM RENDER DATA name:"+JSON.stringify(data))
        this.$el.html(this.template(data));
        return this;
    }
});
