$(document).on('traky_init', function() {
    var Slugs = (function() {

        var self = {};

        self.init = function() {
            $(document).on('keyup', '.has-slug', function() {
                if ($(this).hasClass('slug-mode-add')) {
                    var title  = $(this).val();
                    var slug   = self.parse(title);
                    self.set(slug);
                }
            });

            $(document).on('click', 'span.slug-view', function() {
                $('.slug-edit').removeClass('hide');
                $('.slug-text').addClass('hide');
            });

            $(document).on('click', '#slug-edit-save', function(e) {
                e.preventDefault();
                var slug = self.parse($('#slug-input').val());
                self.set(slug);

                // al editar manualmente el slug impedimos que se vuelva
                // a editar nuevamente si cambia el título
                $('.has-slug').removeClass('slug-mode-add');
                $('.has-slug').removeClass('slug-mode-edit');

                $('.slug-text').removeClass('hide');
                $('.slug-edit').addClass('hide');
            });

            $(document).on('click', '#slug-edit-cancel', function(e) {
                e.preventDefault();
                var slug = self.parse($('span.slug-view').html());
                self.set(slug);
                $('.slug-text').removeClass('hide');
                $('.slug-edit').addClass('hide');
            });
        };

        self.set = function(slug) {
            // añadimos contenido segun sea un span o input
            $('.slug-view').each(function() {
                if ($(this).is('input')) {
                    $(this).val(slug);
                } else {
                    $(this).empty().append(slug);
                }
            });
        };

        self.parse = function(str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
            var to   = "aaaaaeeeeeiiiiooooouuuunc------";
            for (var i=0, l=from.length ; i<l ; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }

            str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

            return str;
        };

        return self;
    })();

    traky.slugs = Slugs;
    traky.slugs.init();
});
