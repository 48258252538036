window.EventsGroupsListItemView = Backbone.View.extend({

    tagName: 'tr',
    template: JST["groups-list-item"],
    events: {
        "click .group-edit": "editItem",
        "click .group-delete": "deleteItem"
    },

    initialize: function() {
        this.model.bind('change', this.render, this);
        this.model.bind('remove', this.remove, this);
    },

    remove: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.$el.remove();
    },

    delete: function() {
        var that = this;
        this.model.destroy({
            success: function () {
                notify.info('Grupo eliminado.', true);
                that.remove();
            },
            error: function() {
                bootbox.alert('Ha ocurrido un error, por favor, inténtalo de nuevo.');
            }
        });
    },

    deleteItem: function(e) {
        var self = this;

        if (e.ctrlKey) {
            this.delete();
        } else {
            bootbox.confirm("Seguro que deseas eliminar este grupo?", function(result) {
                if(result) {
                    self.delete();
                }
            });
        }
    },

    editItem: function(event) {
        new EventsGroupForm({model:this.model}).render();
    },

    render:function () {

        var data = this.model.toJSON();
      //  this.$el.html(Handlebars.compile(JST["events/groups-list-item.hbs"](data)));
        this.$el.html(this.template(data));
        return this;
    }
});
