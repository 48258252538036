window.TrackerCommandsListItem = Backbone.View.extend({

    tagName: 'tr',
    template: JST["commands-list"],
    events: {
        'click .command-remove': 'deleteCommand'
    },

    initialize: function(options) {
        this.model.bind('remove', this.removeCommand, this);
        this.model.bind('sync', this.commandUpdated, this);
        this.listenTo(this.model, 'remove', this.removeCommand);
        this.listenTo(this.model, 'sync', this.commandUpdated);
    },

    render:function () {
      console.log("render command list item")
        var data = this.model.toJSON();
        if(data.release){
			data.release_time = time_ago(data.release/1000,2);
        }
      //  this.$el.html(Handlebars.compile(JST["commands-item"](data)));
        this.$el.html(this.template(data));
        return this;
    },

    removeCommand: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind().remove();
    },

    commandUpdated: function() {
        this.render();
        //notify.success('Se ha guardado con éxito', true);
    },

    deleteCommand: function() {
        if(confirm("Estas? seguro?")) {
            this.model.destroy();
        }
    }
});
