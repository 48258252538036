window.TracksListItemView = Backbone.View.extend({

    tagName: 'tr',
    template: JST["track-list-item"],
    events: {
        "click .remove-track": "removeTrack",
        "click .btn-open-map": "showTrack"
    },

    render:function () {
        var data = this.model.toJSON();
        if(data.totals) {
            if(data.totals.up) data.totals.up = data.totals.up.toFixed(0);
            data.totals.down = data.totals.down.toFixed(0);
            data.totals.distance = data.totals.distance.toFixed(0);
        }
        this.$el.html(this.template(data));
        return this;
    },

    showTrack: function() {
        var modal = new TrackMapView({
            model: this.model
        });
        modal.render();
    }

    /*
    removeTrack: function(e) {
        var that = this;
        var remove = function() {
            that.model.destroy({
                success: function() {
                    notify.success('Sim eliminada.');
                    that.undelegateEvents();
                    that.$el.removeData().unbind();
                    that.$el.fadeOut('fast', function() {
                        that.remove();
                    });
                },
                error: function() {
                    that.$el.fadeIn();
                    bootbox.alert('Ha ocurrido un error, por favor, inténtalo de nuevo.');
                }
            });
        };

        if (e.ctrlKey) {
            remove();
        } else {
            bootbox.confirm("Seguro que deseas eliminar esta sim?", function(result) {
                if (result) remove();
            });
        }
    },
    */
});
