// Playerd
window.TrackModel = Backbone.Model.extend({
    idAttribute: '_id',
    urlRoot: '/api/tracks',
    initialize: function() {

        var self = this;

        // Esto no tiene sentido, sections no va a ningún sitio
      /*  var sections = new TracksSectionsCollection();
        _.each(this.attributes.sections, function(section) {
            section.track_id = self.id;
            sections.add(new TrackSectionModel(section));
        });

        var waypoints = new TracksWaypointsCollection();
        _.each(this.attributes.wpts, function(wpts) {
            wpts.track_id = self.id;
            waypoints.add(new TrackWaypointModel(wpts));

        });
*/
    },
    defaults: {
        /**/
        /*ip: '',
        sim: '',
        date: '',
        status: ''*/
    }

    /*
    // override del save, quiere un evento beforeSave()!
    save: function (key, val, options) {
        this.beforeSave(key, val, options);
        return Backbone.Model.prototype.save.call(this, key, val, options);
    },

    beforeSave: function (key, val, options) {
        // le damos la vuelta a la tortilla (dmy -> ymd)
        var date = this.attributes.date.split(/[.,\/ -]/).reverse();
        // creamos objeto date con ymd
        var date = new Date(date);
        // obtenemos timestamp
        this.attributes.date = date.getTime();
    },

    parse: function(model) {
        // format date
        if (model.date) {
            var date = new Date(model.date);
            model.date = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
            model.date = model.date.join('-');
        }

        return model;
    }
    */
});
