window.EventsSessionsListView = Backbone.View.extend({

    tagName: 'div',
    className: 'panel panel-default',
    template: JST["events-sessions-list"],
    events: {
        'click #session-add': 'addSession',
        'click #session-save': 'saveSession'
    },

    initialize: function() {
      /*  _(this).bindAll('add');
        this.collection.bind('add', this.add);
        */
        this.listenTo(this.collection, 'add', this.add);
    },

    add: function(session) {
        var render = new EventsSessionsListItemView({model:session}).render().el;
        $(this.el).find('#table-sessions').append(render);
    },

    addSession: function() {
        var session = new EventSession();
        this.collection.add(session);
        new EventsSessionForm({model:session}).render();
    },

    saveSession: function(e) {
        if (e) e.preventDefault();

        var sessionData = {
            name: $('#session-name').val(),
            days: $('#session-days').val()
        };

        $.ajax({
            url: '/api/events/' + this.model.get('_id') + '/session-meta',
            data: sessionData,
            type: 'post',
            dataType: 'json'
        }).done(function(data) {
            console.log('data', data);
        }).fail(function(xhr) {
            console.log('fail', xhr);
        });
    },

    render: function() {
        this.$el.html(this.template(this.model));


        return this;
    }
});
