window.TracksListView = Backbone.View.extend({

    tagName: 'div',
    className: 'tracksList',
    template: JST["track-list"],

    initialize: function() {
        //this.collection.bind('add', this.add);
        this.listenTo(this.collection, 'add', this.add);

    },

    add: function(track) {
        $('#loading-tracks').remove();
        var itemView = new TracksListItemView({model:track});
        $('#table-tracks').append(itemView.render().el);
    },

    render:function () {
  
        this.$el.html(this.template());
        traky.setHGroup('Tracks', [{
            text: 'Nuevo Track',
            type: 'primary',
            link: '#admin/tracks/add'
        }]);

        return this;
    }
});
