
window.TrackerCommandsListView = Backbone.View.extend({

    tagName: 'div',
    className: 'commandsList',
    template: JST["commands"],
    events: {
        'click #command-submit': 'submitCommand'
    },

    initialize: function() {
        /*_(this).bindAll('add', 'remove');
        this.collection.bind('add', this.add);
        this.collection.bind('remove', this.remove);
*/
        this.listenTo(this.collection, 'add', this.add);
        this.listenTo(this.collection, 'remove', this.remove);

    },

    add: function(command) {
        $(this.el).find('table tbody').append(new TrackerCommandsListItem({model:command}).render().el);
    },

    remove: function() {

    },

    render: function(data) {

      //  $(this.el).html(Handlebars.compile(JST['commands'](data)));
        this.$el.html(this.template(data));
        return this;
    },

    submitCommand: function() {

        var data = {
            alias: $('#command-action').val(),
            type: $('#command-type').val(),
            data: $('#command-payload').val()
        };

        if(data.alias=="-1") {
            alert('Tienes que seleccionar algo');
            return;
        }
        else if(data.alias=="raw") {
            data.alias="";
            if(data.type=="") {
                alert("Es necesario el RAW");
                return;
            }
        }

        var command = new TrackerCommand({
            command:data
        });

        this.collection.add(command);

        command.save({},{
            success: function() {
                alert('moooooola');
            },
            error: function() {
                alert('no mola');
            }
        });

        console.log(command);
    }
});
