window.EventsUserForm = Backbone.View.extend({

    tagName: 'div',
      template: JST['users-form-edit'],
    className: 'modal fade edit-user',
    id: 'edit-user',
    attributes: {
        "tabindex":"-1",
        "role":"dialog",
        "aria-labelledby":"editParticipant",
        "aria-hidden":"true"
    },

    events: {
        'click .btn-edit-user': 'update',
        'click .btn-user-cancel': 'remove',

        'click #user-notes-add': 'notes_add',
        'click #user-notes-cancel': 'notes_cancel',
        'click #user-notes-open-box': 'notes_open',
        'click .user-notes-remove': 'notes_remove',

        'click .user-delete-tracker': 'delete_tracker'

        // 'click #add-status-history': 'add_status_history',
        // 'click .user-status-history-remove': 'remove_status_history'
    },

    previousAttributes: null,

    initialize: function(options) {
        options = options || {};
        console.log('options', options);
        this.previousAttributes = this.model.previousAttributes();
        this.model.bind('change:trackers',this.change,this);
    },

    change: function() {
        notify.info('cambio de trackers!', true);
    },

    remove: function() {
        this.$el.modal('hide');
    },

    render: function() {

        var self = this;
        var data = this.model.toJSON();
        data.twitter = data.twitter || {};

        this.notes = data.notes || [];

        data.form_title = "Añadir participante";
        data.form_submit = "Añadir";
        if(!this.model.isNew()) {
            data.form_title = "Modificar participante";
            data.form_submit = "Modificar";
        }

      //  this.$el.html(JST["events/users-form-edit.hbs"](data));
          this.$el.html(this.template(data));
        this.$el.find('.modal-dialog').first().width(900);


        $('#main').append(this.el);

        this.$el.modal('show')
            .on('hidden.bs.modal',function(){
                if(self.model.isNew()) self.model.destroy();
                self.undelegateEvents();
                self.$el.removeData().unbind().remove();
            })
            .on('shown.bs.modal', function() {
                self.notes_generate();
            });

        this.$el.keypress(function(event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                self.update();
            }
        });

        //$('#table-user').append(tpl);
        var trackersO = app.eventTrackersCollection.filter(function(tracker){
            if(!(tracker.user_id)) {
                return true;
            }
            else {
                return false;
            }
        });

        var trackers = [];

        _.each(trackersO,function(tracker) {
            trackers.push(tracker.toJSON());
        });

        $('#tracker-edit-user')
            .typeahead('destroy') // prefetch!
            .typeahead({
                local: trackers,
                ttl: 1,
                valueKey: 'name',
                limit: 15
            }).on('typeahead:selected',function(e,datum){

                var trackers = _.clone(self.model.get('trackers'));
                trackers.push(datum._id);
                self.model.set('trackers',trackers);

                console.log(trackers);
                console.log('no es el mismo model?');
                console.log(self.model);
                // Esto tiene que provocar un sync en los trackers
                // Si hay un save claro
            });

            /*
            .keypress(function(event){
                var keycode = (event.keyCode ? event.keyCode : event.which);
                if(keycode == '13'){
                    var datum = $('input.typeahead').data().ttView.dropdownView.getFirstSuggestion().datum;
                    that.collection.create(datum);
                    that.$('.typeahead').typeahead('setQuery','');
                }
            })*/

        return this;

    },

    update: function() {

        var self = this;

        this.model.set({
            display_name: $('#name-edit-user').val(),
            group: $('#group-edit-user').val(),
            status: $('#status-edit-user').val(),
            notes: self.notes,
            tags: traky.utils.toTags($('#tags-edit-user').val()),
            info:{
                type: $('#type-edit-user').val(),
                picture: $('#picture-edit-user').val(),
                icon: $('#icon-edit-user').val(),
                iconurl: $('#iconurl-edit-user').val(),
                order: parseInt($('#order-edit-user').val(),10),
                dorsal: $('#dorsal-edit-user').val(),
                team: $('#team-edit-user').val(),
                extra: $('#info-edit-extra').val(),
                color: $('#color-edit-user').val(),
        //        : $('#-edit-user').val(),
                more_info: $('#more_info-edit-user').val(),
                twitter: {
                    hashtag: $('#twitter-hashtag').val(),
                    from: traky.dateToMongo($('#twitter-from').val()),
                    to: traky.dateToMongo($('#twitter-from').val())
                }
            }
        });

        console.log('BEFORE_USER_SAVE: ', this.model.toJSON());

        this.model.save(null, {
            success: function(){
                console.log('USER_SAVE');
                self.remove();
            },
            error: function() {
                console.log('USER_SAVE_ERROR');
            }
        });
    },

    delete_tracker: function(e) {
        e.preventDefault();
        $el = $(e.currentTarget);
        var _event_id = this.model.collection._event_id;
        var _tracker_id = $el.attr('data-tracker_id');
        var delete_url = [
            '/api/events',
            _event_id,
            'trackers',
            _tracker_id
        ].join('/')

        $.ajax({
            type: 'DELETE',
            dataType: 'json',
            url: delete_url,
            success: function() {
                alert('El tracker se ha desvinculado, actualiza la pagina para reflejar los cambios.');
            },

            error: function() {
                traky.flash.error("Error al desvincular el tracker");
            }
        })
    },

    // STATUS HISTORY
    add_status_history: function() {

        var data = {
            timestamp: $('#status-history-timestamp').val(),
            source: $('#status-history-source option:selected').val(),
            show: $('#status-history-show option:selected').val(),
            desc: $('#status-history-desc').val()
        };

        var that = this;

        var arr = this.model.get('status_history');
        arr.push(data);
        this.model.set('status_history', arr);
        this.model.save(null,{
            success: function(m) {
                that.status_history_generate();
                $('#status-history-timestamp').val('');
                $('#status-history-desc').val('');
            }
        });

    },

    remove_status_history: function(e) {
        var that = this;
        bootbox.confirm('Eliminar elemento?', function(result) {
            if (result) {
                that.model.attributes.status_history.splice(e.target.parentNode.parentNode.getAttribute('data-index'), 1);
                that.status_history_generate();
            }
        });
    },

    status_history_generate: function() {
        var data = {
            status_history: this.model.attributes.status_history
        };
        var status_history = JST['users-status-history'](data);
        $('#user-status-history-list').empty().append(status_history);
        this.model.save();
    },



    // NOTES
    notes: [],
    notes_setup: function() {
        $('#user-notes-text').val('');
    },

    notes_generate: function() {
        var data = {
            notes: this.notes
        };
        var notes = JST['user-notes'](data);
        $('#notes').empty().append(notes);
    },

    notes_open: function(e) {
        e.preventDefault();
        $('#user-notes-list').addClass('hide');
        $('#user-notes-box').removeClass('hide');
        $('#user-notes-text').val('');
    },

    notes_add: function() {
        var date = traky.dateFormatted(date);
        var note = {
            text: $('#user-notes-text').val(),
            author: $.cookie('traky.name'),
            date: traky.dateToMongo(date)
        };

        console.log('note add');

        if (_.isEmpty(note.text)) {
            bootbox.alert('Escribe la nota o pulsa botón cancelar.');
            return;
        }

        traky.unloadMessage = true;

        this.notes.push(note);
        this.notes_generate();
        this.notes_cancel();
        this.model.save();
    },

    notes_cancel: function() {
        $('#user-notes-list').removeClass('hide');
        $('#user-notes-box').addClass('hide');
    },

    notes_remove: function(e) {
        console.log('remove note');
        var that = this;
        bootbox.confirm('Eliminar nota?', function(result) {
            if (result) {
                var index = $(e.target).closest('li').attr('data-index');
                that.notes.splice(e.target.parentNode.parentNode.getAttribute('data-index'), 1);
                that.model.save();
                that.notes_generate();
            }
        });
    }
});
