window.EventsGroupForm = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade edit-group',
    template:JST["groups-form-edit"],
    attributes: {
        "tabindex":"-1",
        "role":"dialog",
        "aria-labelledby":"editUsers",
        "aria-hidden":"true"
    },

    previousAttibutes: {},

    events: {
        'click .btn-group-edit': 'update',
        'click .btn-group-cancel': 'cancel',
        'click .member-remove' : 'removeMember',
        'keyup :input#group-name':  "nameChanged"
    },

    initialize: function() {
        _.bindAll(this, 'nameChanged');
        this.previousAttibutes = this.model.toJSON();
    },

    nameChanged: function() {
        var input = this.$('#group-name');
        this.model.set({name: input.val()});
    },

    cancel: function() {

        var self = this;
        if(this.model.changedAttributes()) {

            if(confirm("El grupo se ha modificado, estás seguro de que no quieres guardar los cambios?")) {

                console.log(this.model.isNew());
                if(this.model.isNew()){
                    this.model.destroy();
                }
                else {
                    this.model.set(this.previousAttibutes);
                }
                this.$el.modal('hide');
            }
        }
        else {
            if(this.model.isNew()){
                this.model.destroy();
            }
            this.$el.modal('hide');
        }
    },

    addMember: function(member) {
        $(this.el).find('ul').append(Handlebars.compile(JST["groups-members-list-item"](member)));

    },

    removeMember: function(event) {

        var el = $(event.target).parent().parent();
        var _id = el.attr('data-id');
        // clone para hacer que dispare el evento change
        var members = _.clone(this.model.get('members'));
        var index = members.lastIndexOf(_id);
        if(index!=-1) {
            members.splice(index,1);
            this.model.set({members:members});
        }
        el.remove();
    },

    render: function() {

        var self = this;
        var data = this.model.toJSON();

      //  this.$el.html(Handlebars.compile(JST["events/groups-form-edit.hbs"](data)));
      this.$el.html(this.template(data));
        if (!this.model.isNew()) {
            _.each(data.members, function(member) {
                var memberModel = app.eventUsersCollection.get(member);
                if (memberModel) {
                    self.addMember({_id:member,name:memberModel.get('display_name')});
                }
            });
        }

        $('#main').append(this.el);

        // modal
        this.$el.modal('show')
            .on('hidden.bs.modal',function(){
                self.undelegateEvents();
                self.$el.removeData().unbind().remove();
            })
            .on('shown.bs.modal',function(){

            });

        // typeahead
        this.typeahead = this.$('#addMember')
            .typeahead('destroy')
            .typeahead({
                name: 'eventUsers',
                local: app.eventUsersCollection.toJSON(),
                valueKey: 'display_name',
                ttl: 0,
                limit: 15
            })
            .on('typeahead:selected', function(e, data) {

                if(_.contains(self.model.get('members'),data._id)) {
                    notify.error('Ya existe.', true);
                }
                else {
                    // clone para hacer que dispare el evento change
                    var members = _.clone(self.model.get('members'));
                    members.push(data._id);
                    self.model.set({members:members});
                    self.addMember({_id:data._id,name:data.display_name});
                }
                // Reset query
                self.$('#addMember').typeahead('setQuery', '');
            });

        // guarda el grupo al pulsar enter sobre un input
        this.$el.keypress(function(event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode === 13) {
                self.update();
            }
        });

        return this;
    },

    update: function() {

        var self = this;
        this.model.save({}, {
            success:function() {
                notify.info('Grupo actualizado', true);
                self.$el.modal('hide');
            }
        });
    }
});
