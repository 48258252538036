window.EventsUsersListItemView = Backbone.View.extend({

    tagName: 'tr',
    className: 'user_row',
    template: JST["events-users-list-item"],

    events: {
        "click .user-remove": "userDelete",
        "click .user-edit": "userEdit",
        "click .user-upload": "userUploadForm",
        'click .user-stats': 'show_stats',
        'click .show-markercluster': 'show_markercluster'
    },

    initialize: function() {
        var that = this;
    /*    this.model.bind('sync', this.userUpdated, this);
        this.model.bind('remove', this.userRemove, this);
*/
        this.listenTo(this.collection, 'add', this.add);
        this.listenTo(this.collection, 'remove', this.remove);
        this.model.on('edited', function() {
            that.render();
        });

        $(document).on('click', '#show-markercluster', function(ev) {
            that.show_markercluster(ev);
        });

    },

    show_stats: function(ev) {

        // var loaderInterval = setInterval(function() {
        //     $('#loader').html($('#loader').html() + '.');
        // }, 66);

      
        $('#trackId').val( $(ev.target.parentNode.parentNode).find('.id')[0].value);


        // var data = {
        //     status_history: this.model.attributes.status_history
        // };

        // var status_history = JST['users/users-status-history.hbs'](data);

        // $('#user-status-history-list').empty().append(status_history);
        // this.$el.find('#user-status-history-list').append(status_history);
       

        var userStatistics = new UserStatistics({model: this.model.toJSON()});
        $('body').append(userStatistics.render().el);


    },

    show_markercluster: function(ev) {

        var getPopupMarker = function(index, data) {
            var date = new Date(data.gps);
            var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            return '<p>Index: ' + index + '</p>' + '<p>Date: ' + date.toLocaleDateString() + ' ' + hour + ':' + minutes + '</p>' + '<p>Timestamp: ' + date.getTime() + '</p>' + '<p>Type: ' + data.type + '</p>' + '<p>Sog: ' + data.sog + '</p>' + '<p>Cog: ' + data.cog + '</p>' + '<p>Alt: ' + data.alt + '</p>';
        };

        var clustersList = [];

        if (!this.markercluster) {

            this.markercluster = new L.MarkerClusterGroup();

            var _arr = [];

            for (var i in this.latLng) {
                _arr = this.latLng[i];
            }

            var myIcon = L.icon({
                iconUrl: 'http://commercealouergrenoble.com/images/Map-Marker-Marker-Outside-Azure-icon.png',
                iconSize: [35, 35]
            });

            for (var i = 0, len = this.latLng.length; i < len; ++i) {
                clustersList.push(L.marker(this.latLng[i], {
                    icon: myIcon
                }).bindPopup(getPopupMarker(i, window.globalData[i])));
            }
        }


        if ($(ev.target).prop('checked')) {
            this.markercluster.addLayers(clustersList);
            this.map.addLayer(this.markercluster);
            return;
        }
        this.map.removeLayer(this.markercluster);
    },

    userUploadForm: function() {
        new EventsUserUploadForm({
            model: this.model
        }).render();
    },

    userDelete: function() {
        var that = this;
        bootbox.confirm("Seguro que deseas eliminar este usuario del evento?", function(result) {
            if (result) that.model.destroy();
        });
    },

    userRemove: function() {
       
        this.undelegateEvents();
        this.$el.removeData().unbind().remove();
    },

    userEdit: function() {
        new EventsUserForm({
            model: this.model
        }).render();
    },

    userUpdated: function() {
        this.render();
        //notify.success('Se ha guardado con éxito', true);
    },

    render: function() {

        var data = this.model.toJSON();
        data.info.frontEndIcon = "url";
 
        // corregir la url de iconos de tipo cog (como barco)
        data.info.iconurl = (function(url) {
            if (!url) return url;

            var filename = url.split('/').pop();
            var ext = filename.split('.');
            if (ext.length === 1) {
                return url + '/1.png';
            }
            return url;

        })(data.info.iconurl);
     
        this.$el.html(this.template(data));

        return this;
    },

    disabled: function() {
        $(this.el).css({
            opacity: 0.5,
            'pointer-events': 'none'
        });
    },

    enabled: function() {
        $(this.el).css({
            opacity: 1,
            'pointer-events': 'auto'
        });
        this.render();
    }
});
