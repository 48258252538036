window.EventsMediaListView = Backbone.View.extend({

    tagName: 'div',
    className: 'panel panel-default',
    template: JST['events-media-list'],

    events: {
        "click #media-form-open": "addMedia"
    },

    initialize: function(options) {
      /*  _(this).bindAll('add');
        this.collection.bind('add', this.add);
        */
          this.listenTo(this.collection, 'add', this.add);
        this.event_id = options.event_id;
    },

    add: function(media) {
        var mediaItem = new EventsMediaListItemView({model: media});
        this.$el.find('#thumbnails').append(mediaItem.render().el);
    },

    addMedia: function() {
        var media = new MediaModel();
        this.collection.add(media);
        new EventsMediaForm({model:media, event_id: this.event_id}).render();
    },

    render:function () {
        this.$el.html(this.template());
        return this;
    }
});
