/* jshint unused:false */

function contains(str, str2) {
    return str2 !== null && str2.length <= str.length && str !== null ? str.indexOf(str2) !== -1 : false;
}

function removeString(str, str2) {
    return str.replace(new RegExp(str2, 'g'), '');
}

String.prototype.repeat = function(num) {
    return new Array(num + 1).join(this);
}


window.google_map = null;

function initialize_map(places, info) {
  console.log("initiaize_map");
    var bounds = new google.maps.LatLngBounds(); // empty bounds object

    // use first coordinates in the json to initiate the map
    var place_lat_lng = new google.maps.LatLng(places[0][0], places[0][1]);

    var mapOptions = {
        zoom: 18,
        center: place_lat_lng,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    var elem=document.getElementById('map-trackers');
    console.log("div del mapa:"+elem);


    google_map = new google.maps.Map(document.getElementById('map-trackers'),
         mapOptions);
console.log("google_map:"+google_map);
    // loop through all the places to get markers
    /*
    for (var i = 0; i < places.length; i++) {
        var place = places[i];
        var myinfowindow = new google.maps.InfoWindow({
            content: '<h3>' + info[i].name + '</h3><p><strong>Estado:</strong><br/>' + (info[i].status || 'Sin información...') + '</p><p><strong>Tipo:</strong><br/>' + (info[i].type || 'Sin información...') + '</p><p><strong>Localización:</strong><br/>' + (info[i].location || 'Sin información...') + '</p><p><strong>Actividad:</strong><br/>' + info[i].elapsed_time + '</p>'
        });

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(place[0], place[1]),
            map: google_map,
            animation: google.maps.Animation.DROP,
            infowindow: myinfowindow
        });

        marker.setMap(google_map);
        bounds.extend(marker.getPosition());

        google.maps.event.addListener(marker, 'click', function() {
            this.infowindow.open(google_map, this);
        });

    }
*/



  //  google_map.fitBounds(bounds); // show all the markers.
}

function map_resize() {
    google.maps.event.trigger(google_map, 'resize');
}

// Set a callback to run when the Google Visualization API is loaded.
//google.setOnLoadCallback(drawChart);


// Callback that creates and populates a data table,
// instantiates the pie chart, passes in the data and
// draws it.

function drawChart(data) {
    console.log(JSON.stringify(data));
    // Create the data table.
    var table = google.visualization.arrayToDataTable([
        ['Name', 'Donuts eaten'],
        ['Michael', 5],
        ['Elisa', 7],
        ['Robert', 3],
        ['John', 2],
        ['Jessica', 6],
        ['Aaron', 1],
        ['Margareth', 8]
    ]);
    var rows = [];
    $.each(data, function(index, value) {
        //alert(index + ": " + value);
        rows.push(value.name, value.type);
        if (value.name && value.type)
            table.addRows([value.name, value.type]);
    });
    console.log(rows);
    //table.addRows([rows]);

    // Set chart options
    var options = {
        'title': 'How Much Pizza I Ate Last Night',
        'width': 400,
        'height': 300
    };

    // Instantiate and draw our chart, passing in some options.
    var chart = new google.visualization.PieChart(document.getElementById('chart_div'));
    chart.draw(table, options);
}

function pnotify(msg, hide, type) {
    PNotify({
        text: msg,
        type: type,
        styling: 'bootstrap',
        hide: hide || false,
        animation: 'show',
        delay: 4000
    });
}

// hide: true para que desaparezca la notificación
var notify = {
    error: function(msg, hide) {
        new PNotify({
            title: msg,
            type: 'danger'
        });
    },
    info: function(msg, hide) {
        new PNotify({
            title: msg,
            type: 'info'
        });
    },
    success: function(msg, hide) {
        new PNotify({
            title: 'asdf',
            type: 'success'
        });
    },
    notice: function(msg, hide) {
        new PNotify({
            title: msg,
            type: ''
        });
    }
};

// creo que se utiliza cuando hat sumbenus antes era en pages y trackers
function adminMenuControl() {
    var accordions = ['pages', 'trackers'];
    _.each(accordions, function(val, key) {
        if (contains(document.URL, 'admin/' + val)) {
            $('#' + val).collapse('show');
        } else {
            if ($('#' + val).hasClass('in'))
                $('#' + val).collapse('hide');
        }
    });
}


function setSlug(str) {
    $('#slug-view').text(getSlug(str));
    $('#slug').val(getSlug(str));
}

function showModifySlug() {
    $("#input-modify-slug").show();
    $("#input-modify-slug").val($('#slug-view').text());
    $("#btn-modify-slug").show();
    $("#modify-slug").hide();
}

function hideSetSlugElements() {
    $("#input-modify-slug").hide();
    $("#btn-modify-slug").hide();
    $("#slug-view").show();
    $("#modify-slug").show();
}

function getSlug(str) {
    return str.toLowerCase()
        .trim()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

$(document).on('click', '.btn-active-input', function() {
    var input = $(this).parent().parent().find('input');
    if (input.attr('disabled')) {
        $(this).html('<span class="glyphicon glyphicon-ok">');
        input.removeAttr('disabled');
    } else {
        $(this).html('<span class="glyphicon glyphicon-pencil">');
        input.attr('disabled', 'disabled');
    }
});

function isLatLng(latLng) {
    var regex = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/;
    if (regex.test(latLng)) {
        return true;
    }

    return false;
}

function time_ago(timestamp, granularity) {

    timestamp *= 1000;
    var now = new Date().getTime();
    var future;
    //now -= time_zone_offset;

    var difference = now - timestamp;
    var ret = '';
    difference /= 1000;
    var time = 0;

    var periods = [{
        key: "y",
        t: 31536000
    }, {
        key: "m",
        t: 2628000
    }, {
        key: "d",
        t: 86400
    }, {
        key: "h",
        t: 3600
    }, {
        key: "min",
        t: 60
    }, {
        key: "seg",
        t: 1
    }];

    // Si la fecha es superior a la fecha actual
    // se le da la vuelta para obtener cuanto falta para que llegue el momento elegido
    if (difference < 0) {
        difference *= -1;
        future = true;
    }

    if (difference < 5) { // less than 5 seconds ago, let's say "just now"
        ret = "Hace un momento";
        return ret;
    } else {
        for (var i in periods) {
            if (difference >= periods[i].t) {
                time = Math.floor(difference / periods[i].t);
                difference %= periods[i].t;
                ret += (ret ? ' ' : '') + time + ' ';
                ret += ((time > 1) ? (periods[i].key === 'mes') ? periods[i].key + '' : periods[i].key + '' : periods[i].key);
                granularity--;
            }
            if (granularity == '0') {
                break;
            }
        }
        return ret ? future ? '*' + ret : ret : '-';
    }
}


function dataTable(id_table) {
    jQuery.extend(jQuery.fn.dataTableExt.oSort, {
        "title-numeric-pre": function(a) {
            var x = a.match(/title="*(-?[0-9\.]+)/)[1];
            return parseFloat(x);
        },

        "title-numeric-asc": function(a, b) {
            return ((a < b) ? -1 : ((a > b) ? 1 : 0));
        },

        "title-numeric-desc": function(a, b) {
            return ((a < b) ? 1 : ((a > b) ? -1 : 0));
        }
    });
    $('#' + id_table).dataTable({
        "bLengthChange": true,
        "bFilter": true,
        "bSort": true,
        "bInfo": true,
        "bAutoWidth": true,
        "bStateSave": true,
        "sPaginationType": "full_numbers",
        "iDisplayLength": 50,
        "bCaseInsensitive": false,
        "aoColumns": [
            null,
            null,
            null,
            null, {
                "sType": "title-numeric"
            },
            null,
            null
        ],
        "oLanguage": {
            "sSearch": "Filtrar tracker: ",
            "sZeroRecords": "No se han encontrado resultados",
            "sLoadingRecords": "Espere un momento - cargando datos...",
            "sInfoEmpty": "0 trackers",
            "sInfo": "_TOTAL_ trackers ",
            "sInfoFiltered": "de _MAX_"
        }
    });
}

function setHGroup(title, btns) {
    btns = btns || [];
    $('#page-title').html(title);
    $('title').text('Administración - ' + title + ' | VirualChallenge');
    $('#header-btns').empty();

    _.each(btns, function(btn) {
        var type = btn.type || 'primary';
        var classTag = btn.classTag || '';
        var el = '<a href="' + btn.link + '" class="btn btn-' + type + ' '+classTag+'">' + btn.text + '</a>';
        $('#header-btns').append(el);
    });
}

$(function() {

    $(document).on('keydown', '.only-numbers', function (e) {
        if (!this.value.match(/^[0-9]$/g)) {
            e.preventDefault();
        } else {
            console.log(this.value, this.value.match(/^[0-9]$/g));
        }
    });

    $('.left-toggle').click(function() {
        $('.navbar-sidebar').animate({
            left: '0'
        }, 250, function() {
            $('.navbar-sidebar').addClass('active');
            $('.btn-left-menu').click(function() {
                $('.navbar-sidebar').animate({
                    left: '-500px'
                }, 250, function() {
                    $('.navbar-sidebar').removeClass('active');
                });
            });
        });
    });

    $('#modify-slug').click(function() {
        var t = $('<input type="text"/>');
        $('#slug-view').append(t);
    });

    $(document).on('click', 'a[data-link]', function() {
        var link = $(this).attr('data-link');
        if (link) {
            window.location.href = link;
        }
    });

    $(document).on('mouseover', 'tr', function() {
        $(this)
            .find('.table-column-buttons .btn')
            .css('visibility', 'visible');
    });
    $(document).on('mouseout', 'tr', function() {
        $(this)
            .find('.table-column-buttons .btn')
            .css('visibility', 'hidden');
    });
});

function gamesCombo(model, opts) {

    var that = this;
    this.opts = _.extend({
        type: 'gifts',
        el: '_game_id'
    }, opts);

    $el = typeof this.el === 'string' ? $(this.opts.el) : this.opts.el;

    console.log('$el', $el, that.opts);

    // funciona a modo de callback por si hay que hacer un fetch asincrono.
    this.renderCombo = function(collection, data) {

        var current = '';
        if (model.get('_game_id')) {
            current = model.get('_game_id');
        }

        var options = '<option value="0">Selecciona un juego</option>';
        _.each(data, function(game) {
            if (game.type != that.opts.type) {
                return;
            }

            if (game._id == current) {
                options += '<option value="'+game._id+'" selected="selected">'+game.name+'</option>';
            } else {
                options += '<option value="'+game._id+'">'+game.name+'</option>';
            }
        });

        $el.append(options).select2();
        $el.on("change", function(e) {
            model.set('_game_id', e.val);
        });
    };

    getEventGameFromGame(model.get('_game_id'), function(game) {
        app.gamesList = new GamesCollection({game_id: game._game_id}).fetch({
            success: function(collection, data) {
                that.renderCombo(collection, data);
            }
        });
    });
}

function wedgesCombo(model, opts) {

    var that = this;
    this.opts = _.extend({
        el: 'category'
    }, opts);

    $el = typeof this.el === 'string' ? $(this.opts.el) : this.opts.el;

    this.renderCombo = function(collection, data) {
        var current = '';
        if (model.get('category')) {
            current = model.get('category');
        }

        var options = '<option value="0">Selecciona un quesito</option>';
        _.each(data, function(wedge) {
            // almacenamos solo los wedges qeu pertenezcan al juego actual.
            if (wedge._game_id != model.get('_game_id')) {
                return;
            }

            if (wedge._id == current) {
                options += '<option value="'+wedge._id+'" selected="selected">'+wedge.name+'</option>';
            } else {
                options += '<option value="'+wedge._id+'">'+wedge.name+'</option>';
            }
        });

        $el.append(options).select2();
        $el.on("change", function(e) {
            model.set('category', e.val);
        });
    };

    if (app.wedgesList) {
        this.renderCombo(app.wedgesList, app.wedgesList.toJSON());
    } else {
        app.wedgesList = new WedgesCollection().fetch({
            success: function(collection, data) {
                that.renderCombo(colleciton, data);
            }
        });
    }
}

function getEventGameFromGame(game_id, callback) {
    $.get('/api/games/' + game_id, function(game) {
        callback(game);
    });
}

/**
 * Esta funcion se debe utilizar con el metodo _.groupBy de lo-dash o underscore
 * A partir de un array unidemensional de objetos, genera un nuevo array
 * multidimensional donde los "hijos" se anidan dentro de los "padres".
 * require que cada objeto tenga una propiedad "_id" y un "parent"
 *
 * @example <caption>Organizar en forma de arbol una coleccion backbone</caption>
 * var list = _.groupBy(Collection.toJSON(), 'parent');
 * var tree = buildTree(list[0], list);
 */

function buildTree(branch, list) {
    if (typeof branch === 'undefined') return null;
    var tree = [];
    for (var i = 0; i < branch.length; i++)
        tree.push({
            item: branch[i],
            children: buildTree(list[branch[i]._id], list)
        });
    return tree;
}

function buildTable(branches, callback, count) {

    count = count || 0;
    // para cada recursión hay que crear una variable en el escope local de la funcion
    // y evitar que se vayan sumando valores de diferentes funciones en una misma
    // 1
    //   2
    //     3
    //   4 (error! debe ser 2)
    var localCount = count;

    _.each(branches, function(branch) {

        callback(branch.item, localCount);

        if (branch.children !== null) {
            buildTable(branch.children, callback, ++count);
        }
    });
}

function getTreeIndex(object_id, collection, callback) {
    if (!_.isString(object_id)) {
        object_id = object_id._id;
    }


    var list = _.groupBy(collection, 'parent');
    var tree = buildTree(list[0], list);
    buildTable(tree, function(item, indexTree) {
        if (item._id === object_id) {
            callback(indexTree);
        }
    });
}
