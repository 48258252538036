window.EventsUsersListView = Backbone.View.extend({

    tagName: 'div',
    className: 'panel panel-default',
    template: JST["events-users-list"],


    event_data:[],
    event_id:0,

    events: {
        'click #add-user': 'userAdd',
        'click #users-check-all': 'users_check_all',
        'click #btn-modal-edit-group': 'modal_edit_group',
        'click #btn-modal-colorear': 'modal_edit_colorear',
        'click #btn-modal-fillusers': 'modal_edit_fillusers',
        'click #update-group': 'update_group',
        'click #csv-save': 'csvUpload',
        'click #crear-lote': 'crearLote'

        // 'click #add-status-history': 'add_status_history',
        // 'click .user-status-history-remove': 'remove_status_history'
    },

    initialize: function(options) {


    this.listenTo(this.collection, 'add', this.add);

        this.event_id = options.event_id ||  false;
        this._event_id = options._event_id ||  false;
    },


  add: function(user) {
  //  console.log("icono:"+JSON.stringify(user));
        var user_group = [];
        _.each(this.event_data.groups, function(group) {
            _.each(group.members, function(member) {
                if (user.get('_id') === member) {
                    user_group.push(group.name);
                }
            });
        });

        user.set('group', user_group);
        var item = new EventsUsersListItemView({model: user});
        $(this.el).find('table tbody').append(item.render().el);
    },

    userAdd: function() {
        var user = new EventUser();
        user.set('session', {event_id: this.event_id});
        this.collection.add(user);
        new EventsUserForm({model: user}).render();
    },

    render: function(event_data) {

      //console.log("RENDER userlist render event_data:"+JSON.stringify(event_data));

        this.event_data = event_data;
        this.event_id=event_data.event_id;

      

        var that = this;
        this.$el.html(this.template({event_id: this._event_id}));

        this.collection.fetch({
            success: function() {
                $('#users-length').html(that.collection.length);
            }
        });


        return this;
    },

    set_user_trackers: function() {
        var trackers = [];
        $('.tracker-data').each(function() {
            trackers.push(JSON.parse($(this).val()));
        });

        $('#tracker-edit-user')
            .typeahead('destroy')
            .typeahead({
                cache: false,
                ttl: 1,
                local: trackers,
                valueKey: 'name',
                limit: 15
            });

        $('#tracker-edit-user')
            .on('typeahead:selected', function(e, datum) {
                if (datum.tracker_id) {
                    $('#tracker-user-id').val(datum.tracker_id);
                }
            });

    },

    csvUpload: function() {
        $('#csv-form').ajaxSubmit({
            success: function() {
                alert("la importacion se ha realizado");
                bootbox.confirm('El archivo se ha procesado. Pulsa el botón aceptar para recargar la pagina y ver los cambios.', function(result) {
                    if (result) {
                        document.location.reload();
                    } else {
                        $('#csv-upload').modal('hide');
                    }
                });
            },

            error: function() {
                alert('Error, por favor, inténtalo de nuevo');
            }
        });
    },

    // user
    // ------------------------------------------------------------------------

    user_add: function() {

        var data = {
            img: $('#img-user').val(),
            name: $('#name-user').val(),
            user_id: $('#dorsal-user').val(),
            dorsal: $('#dorsal-user').val(),
            color: $('#color-user').val(),
            icon: $('#icon-user').val(),
            type: $('#type-user').val(),
            team: $('#team-user').val(),
            status: $("#status-user").val(),
            tracker: $('#tracker-user-id').val()
        };

        $('#add-user').modal('hide');

        $("#form-add-user")[0].reset();

        data.data = JSON.stringify(data);
        var tpl = JST["events/users-form.hbs"](data);
        $('#table-user').append(tpl);
    },

    user_save_all: function() {
        var id = this.model.get('_id');

        if (id) {
            this.user_save(id, function(err) {
                if (!err) {
                    notify.success('Los participantes se han guardado.', true);
                } else {
                    notify.error('Ha ocurrido un error, por favor, inténtalo de nuevo.', true);
                }
            });
        } else {
            notify.error('Los participantes no se han guardado, \
                         por favor, guarda primero el evento.', true);
        }
    },

    users_get_selected: function() {
        var users = [];

        $('#table-user tr').each(function() {
            if ($(this).find('.user-check-all').is(':checked')) {
                var user = JSON.parse($(this).find('.user-data').val());
                users.push(user);
            }
        });

        return users;
    },

    users_do_action: function() {
        var users = this.users_get_selected();
        if (users) {

            var url = $('#users-actions').val();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: users,
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('authorization', '');
                },
                success: function(data) {
                    notify.success('La acción se ha ejecutado', true);
                    consoloe.log(data);
                },
                error: function() {
                    notify.error('Error al ejecutar la acción');
                }
            });

        } else {
            bootbox.alert('No has seleccionado usuarios.');
        }
    },

    users_check_all: function() {
        if ($('#users-check-all').prop('checked')) {
            $('#table-users tr').find('.checkbox').each(function() {
                $(this).prop('checked', true);
            });
        } else {
            $('#table-users tr').find('.checkbox').each(function() {
                $(this).prop('checked', false);
            });
        }
    },

    modal_edit_group: function() {
        var checkeds = false;
        $('#table-users tr').find('.checkbox').each(function() {
            if ($(this).is(':checked')) {
                checkeds = true;
            }
        });
        if (checkeds) {
            $('#modal-edit-group').modal();
        } else {
            bootbox.alert('No has seleccionado usuarios.');
        }
    },
modal_edit_colorear:function(){
  var action='/users/randomcolors';
    var data = {icon_label: false};
  //var url = this.model.url() + "/" + action;
var url="/api/events/"+this._event_id+action;
  console.log("Ejecutando modal_edit_colorear: " + url);

  $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: data,
      success: function() {
        bootbox.alert('Fin colorear');
        bootbox.alert("El colorear se ha realizado con exito", function(){
        document.location.reload(); // TODO fetch collection and render!
       });


      },
      error: function() {
          bootbox.alert('Error al ejecutar la acción colorear');

      }
  });

},
modal_edit_fillusers:function(){
  var action='/actions/fillusers';

    var data = {icon_label: false};
  //var url = this.model.url() + "/" + action;
  var url="/api/events/"+this._event_id+action;
  console.log("Ejecutando fillusers: " + url);

  $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: data,
      success: function() {

        bootbox.alert("El fillusers se ha realizado con exito", function(){
        document.location.reload(); // TODO fetch collection and render!
       });


      },
      error: function() {
          bootbox.alert('Error al ejecutar la acción fillusers');

      }
  });

},


    update_group: function() {
        var that = this;

        var data = {
            type: $('#edit-group-type').val()   || false,
            color: $('#edit-group-color').val() || false,
            icon: $('#edit-group-icon').val()   || false
        };

        if ($('#colorCheckbox:checked')) {
            data.color = '';
        }

        var total = 0;
        var selectedUsers = [];

        $('#table-user .user-checkbox:checked').each(function() {
            total = total + 1;
            selectedUsers.push($(this).data('id'));
        });

        that.collection.forEach(function(model) {
            if (_.indexOf(selectedUsers, model.get('_id')) !== -1) {
                if (data.type !== false)  model.attributes.info.type = data.type;
                if (data.color !== false) model.attributes.info.color = data.color;
                if (data.icon !== false)  model.attributes.info.icon = data.icon;

                model.save(null, {
                    success: function(user) {
                        model.trigger('edited'); // le dice a la vista que haga render
                        if (--total === 0) {
                            bootbox.alert('Usuarios actualizados', function() {
                                $('#modal-edit-group').modal('hide');
                            });
                        }
                    }
                });
            }
        });

    },

    crearLote: function() {
        var number = $('#lote-number').val();
        if (!_.isNumeric(number)) {
            return bootbox.alert('ERROR: se requiere un número');
        }

        var data = {number: number,
                    event_id:this.event_id};
        console.log("CREATE LOTE event id:"+this.event_id);

        $.ajax({
            data: data,
            type: 'post',
            dataType: 'json',
            url: '/api/events/' + this.event_id + '/users/multicreate',
            success: function() {
              bootbox.alert('Se han creado los paticipantes');
              $('#modal-lote').modal('hide');
            },
            error: function() {
              bootbox.alert('No se han podido crear los participantes');
              $('#modal-lote').modal('hide');
            }
        });
    }

});
