window.EventsSponsorForm = Backbone.View.extend({

    tagName: 'div',
    className: 'modal fade edit-sponsor',
    
    id: 'edit-sponsor',
    attributes: {
        "tabindex":"-1",
        "role":"dialog",
        "aria-labelledby":"sponsor-edit",
        "aria-hidden":"true"
    },
    template: JST["events/events-sponsors-form.hbs"],

    previousAttibutes: {},

    events: {
        'click .sponsor-save': 'save',
        'click .sponsor-cancel': 'cancel',
        'click #sponsor-remove-current-picture': 'removeCurrentImage',
        'keyup :input#sponsor-name':  "nameChanged",
        'keyup :input#sponsor-tags':  "tagsChanged",
        'keyup :input#sponsor-url':  "urlChanged",
        'change :input#sponsor-type':  "typeChanged",
        'keyup :input#sponsor-order':  "orderChanged"
    },

    initialize: function() {
        _.bindAll(this, 'nameChanged');
        _.bindAll(this, 'urlChanged');
        _.bindAll(this, 'typeChanged');
        _.bindAll(this, 'orderChanged');
        _.bindAll(this, 'tagsChanged');
        this.previousAttibutes = this.model.toJSON();
    },

    nameChanged: function() {
        tmw.validationClear('#form-sponsor', '#sponsor-name');
        var input = this.$('#sponsor-name');
        this.model.set({name: input.val()}, {validate:true});
    },

    urlChanged: function() {
        tmw.validationClear('#form-sponsor', '#sponsor-url');
        var input = this.$('#sponsor-url');
        this.model.set({url: input.val()}, {validate:true});
    },

    typeChanged: function() {
        tmw.validationClear('#form-sponsor', '#sponsor-type');
        var input = this.$('#sponsor-type');
        this.model.set({type: input.val()}, {validate:true});
    },

    orderChanged: function() {
        tmw.validationClear('#form-sponsor', '#sponsor-order');
        var input = this.$('#sponsor-order');
        this.model.set({order: input.val()}, {validate:true});
    },

    tagsChanged: function() {
        tmw.validationClear('#form-sponsor', '#sponsor-tags');
        var input = this.$el.find('#sponsor-tags');
        this.model.set({tags: tmw.utils.toTags(input.val())}, {validate: true});
    },

    removeCurrentImage: function() {
        $('#sponsor-preview-picture').remove();
        $('#sponsor-picture').removeClass('hide');
    },

    cancel: function() {
        if(this.model.changedAttributes()) {
            if(confirm("El grupo se ha modificado, estás seguro de que no quieres guardar los cambios?")) {
                if(this.model.isNew()){
                    this.model.destroy();
                }
                else {
                    this.model.set(this.previousAttibutes);
                }
                this.$el.modal('hide');
            }
        }
        else {
            if(this.model.isNew()){
                this.model.destroy();
            }
            this.$el.modal('hide');
        }

        this.$el.modal('hide');
    },

    render: function() {

        var self = this;
        var data = this.model.toJSON();

        this.$el.html(this.template(data));
        // modal
        this.$el.modal('show')
            .on('hidden.bs.modal',function() {
                self.undelegateEvents();
                self.$el.removeData().unbind().remove();
            });

        this.$el.keypress(function(event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode === 13) {
                self.save();
            }
        });

        $('#main').append(this.el);

        return this;
    },

    /**
     * Guardamos primero el modelo con los datos básicos del sponsor
     * Una vez creado el modelo actulizamos la imagen
     * @return {[type]} [description]
     */
    save: function() {

        var that = this;

        var data = {
            name: $('#sponsor-name').val(),
            url: $('#sponsor-url').val(),
            type: $('#sponsor-type').val(),
            tags: tmw.utils.toTags($('#sponsor-tags').val()),
            order: $('#sponsor-order').val(),
            picture: $('#sponsor-picture').val()
        };

        // clear errores previos
        tmw.validationClear('#form-sponsor');

        var current = '';
        if ($('#sponsor-current-picture').length) {
            current = $('#sponsor-current-picture').val();
        }

        if (data.picture !== '' || current !== '') {
            this.uploadImage(current, function(picture) {
                if (picture) {
                    data.picture = picture;
                    that.model.save(data, {
                        success: function() {
                            notify.success('El sponsor ha sido guardado', true);
                            that.$el.modal('hide');
                        },

                        error: function() {
                            notify.error('Ha sucedido algún error', true);
                        }
                    }, {validate: true});
                } else {
                    notify.error('ERROR: no se ha podido cargar la imagen', true);
                }
            });
        } else {
            notify.error('No has seleccionado ninguna imagen', true);
        }
    },

    // callback acepta 1 parámetro: el nombre del archivo (string) cargado o false (bool) si error
    uploadImage: function(current, callback) {

        var event_id = $('#_event_id').val();

        // si actualmente hay una imagen la devolvemos tal cual
        if (current) {
            return callback(current);
        }

        $('#picture-form').ajaxSubmit({
            type: 'POST',

            data: {prefix: event_id},

            beforeSubmit: function() {
                $('#upload-progress-container').removeClass('hide');
                $('#sponsor-upload-info').addClass('hide');
            },

            uploadProgress: function(event, position, total, percentComplete) {
                $('#sponsor-upload-progress').css('width', percentComplete + '%');
                console.log(percentComplete);
                if (percentComplete === 100) {
                    $('#sponsor-upload-info').removeClass('hide');
                    $('#upload-progress-container').addClass('hide');
                }
            },

            complete: function() {
                $('#upload-progress-container').addClass('hide');
                $('#sponsor-upload-info').addClass('hide');
            },

            success: function(upload) {
                callback(upload.filename);
            },

            error: function(xhr) {
                console.error(xhr);
                callback(false);
            }
        });
    }
});
