window.TrackersListView = Backbone.View.extend({

    tagName: 'div',
    className: 'trackersList',
    template: JST["trackers-list"],

    events: {
        'keyup #filter-name': 'filter_name',

        'click .btn-add-tracker': 'tracker_add',
        'click .btn-edit-tracker': 'tracker_edit',

        'keyup #name-tracker': 'name_required',

        'click #btn-view-trackers': 'show_trackers_map',
        'click #btn-view-returns': 'returns_wizard',
        'click #btn-show-update-trackers': 'show_update_trackers',
        'click #btn-update-trackers': 'update_trackers',


        'click #remove-filter': 'remove_filter',

        'click #trackers-datatable_paginate': '',

        'click #all-trackers': 'all_trackers_checked',

        'keyup #trackers-datatable_filter input': 'filter_change'
    },

    initialize: function() {
      /*  _(this).bindAll('add', 'remove');
        this.collection.bind('add', this.add);
        this.collection.bind('remove', this.remove);
        */
          this.listenTo(this.collection, 'add', this.add);
          this.listenTo(this.collection, 'remove', this.remove);
    },

    add: function(tracker) {
        $('#trackersLoading').remove();
        $(this.el).find('table tbody').append(new TrackersListItemView({
            model: tracker
        }).render().el);
    },

    remove: function() {
        this.undelegateEvents();
        this.$el.removeData().unbind();
    },

    render: function() {
    
        var data = {trackers: traky.trackers.init};
    /*    data.access = traky.getCheckAccess('trackers');
       
*/
        this.$el.html(this.template(data));
        return this;

    },

    filter_change: function() {
        /* if (e.target.value)
            if (!$('#table-trackers .dataTables_empty').length)
                $('#number-trackers-text').text($('#table-trackers tr').length + ' de ');
            else
                $('#number-trackers-text').text('0 de ');
        else
            $('#number-trackers-text').text('');*/
    },

    remove_filter: function() {
        var oTable = traky.trackers.table;
        oTable.fnFilter('');
        $('#number-trackers-text').text($('#trackers-datatable_info').text());
    },

    tracker_add: function() {
   
      app.navigate('#admin/trackers/add',true);

      /*  var data = {
            name: $('#name-tracker').val(),
            tracker_id: $('#id-tracker').val(),
            type: $('#type-tracker').val(),
            location: $('#location-tracker').val(),
            latest_position: {
                server_timestamp: new Date().getTime()
            },
            elapsed_time: time_ago(new Date().getTime() / 1000, 2),
            status: $("#status-tracker").val()
        };

        if (data.name) {
            var tracker = new TrackersModel();
            tracker.save(data, {
                success: function(a, b, c) {
                    // agregar el nuevo tracker a la tabla
                    app.TrackersListView.add(tracker);
                    $('#add-tracker').modal('hide');
                    $("#trackers-datatable").dataTable().fnDestroy();
                    traky.trackers.dataTable('trackers-datatable');
                    traky.setHGroup('Trackers');
                    $('#number-trackers').text(parseInt($('#number-trackers').text()) + 1);
                    notify.success('Tracker añadido correctamente', true);
                    $("#form-add-tracker")[0].reset();
                },
                error: function(err, data) {
                    notify.success('Error al guardar el tracker', true);
                    console.log('ERROR', err, data);
                }
            });
        } else {
            $('#form-add-tracker .group-title').addClass('has-error');
            notify.info('Es necesario añadir un nombre al tracker', true);
        }
        return false;
        */
    },

    tracker_edit: function() {
        var id = $('#' + $('.btn-edit-tracker').val() + ' .id-edit-tracker').val();

        var data = {
            name: $('#name-edit-tracker').text(),
            tracker_id: $('#id-edit-tracker').val(),
            type: $('#type-edit-tracker').val(),
            status: $("#status-edit-user").val(),
            location: $('#location-edit-tracker').val()
        };

        $('#user-data-' + id).val(JSON.stringify(data));
        $('#' + $('.btn-edit-tracker').val() + ' .name-edit-tracker').attr('src', $('#name-edit-tracker').val());
        $('#' + $('.btn-edit-tracker').val() + ' .id-edit-tracker').text($('#name-edit-tracker').val());
        $('#' + $('.btn-edit-tracker').val() + ' .type-edit-tracker').text($('#type-edit-tracker').val());
        $('#' + $('.btn-edit-tracker').val() + ' .status-edit-tracker').text($('#status-edit-tracker').val());
        $('#' + $('.btn-edit-tracker').val() + ' .location-edit-tracker').text($('#location-edit-tracker').val());


        $('#edit-user').modal('hide');
    },

    update_trackers: function() {
        var self = this;
        var data = {};
        var type = $('#update-type-tracker').val(),
            location = $('#update-location-tracker').val(),
            status = $('#update-status-tracker').val();

        if (type)
            data.type = type;
        if (location)
            data.location = location;
        if (status)
            data.status = status;

        var count;
        $('#table-trackers tr').filter(':has(:checkbox:checked)').each(function() {
            if (!count) {
                count = $(this).length;
            }
            var refresh = $(this).find('.tracker-refresh');
            var tracker = app.trackersList.get($(this).find('.id').val());
            tracker.save(data, {
                success: function() {
                    $(refresh).trigger("click");
                    count--;
                    if (count === 0) {
                        notify.success('Trackers modificados correctamente', true);
                        $('#show-update-trackers').modal('hide');
                    }
                },
                error: function(e) {
                    console.log(e);
                }
            });
        });
        return false;
    },

    show_update_trackers: function() {
        var self = this;
        var elements = [];
        $('#table-trackers tr').filter(':has(:checkbox:checked)').each(function() {
            elements.push(this);
        });

        if (elements.length) {
            $('#show-update-trackers').modal('show');
        } else {
            bootbox.alert('No has seleccionado ningun tracker, o el que has seleccionado no tiene coordenadas');
        }
    },

    show_trackers_map: function() {
        var coord = [];
        var info = [];
        $('#table-trackers tr').filter(':has(:checkbox:checked)').find('.coord').each(function() {
            var data = {
                name: $(this).parent().find('.name').text(),
                type: $(this).parent().find('.type').text(),
                status: $(this).parent().find('.status').text(),
                elapsed_time: $(this).parent().find('.elapsed_time').text(),
                location: $(this).parent().find('.location').text()
            }
            var el = $(this).text().split(',');
            if (el[0] < 90 && el[0] > -90 && el[1] > -180 && el[0] < 180)
                coord.push(el);
            info.push(data);
        });
        if (coord.length) {
            $('#view-trackers-modal').modal('show');

            setTimeout(function() {

                google.maps.event.addDomListener(window, 'load', initialize_map(coord, info))
            }, 200);
        } else {
            bootbox.alert('No has seleccionado ningun tracker, o el que has seleccionado no tiene coordenadas');
        }
    },

    returns_wizard: function() {

        var trackersReturnsC = new TrackersReturnsCollection();
        var trackersReturnsV = new TrackerReturnsView({collection: trackersReturnsC});
        $('body').append(trackersReturnsV.render().el);
        trackersReturnsV.$el.modal('show');
    },
/*
    view_columns: function(e) {
      console.log("TRACKER-LIST VIEW view_columns")
        var oTable = $('#trackers-datatable').dataTable();
        var lengthCols = $('#trackers-datatable th').length;

        if (e.target.id === 'viewMax') {
            for (var i = 1; i < lengthCols - 1; i++) {
                var bVis = oTable.fnSettings().aoColumns[i].bVisible;
                oTable.fnSetColumnVis(i, true);
                if (i == 2) {
                    oTable.fnSetColumnVis(i, false);
                }
            }
        } else if (e.target.id === 'viewMin') {
            for (var i = 1; i < lengthCols - 1; i++) {
                var bVis = oTable.fnSettings().aoColumns[i].bVisible;
                oTable.fnSetColumnVis(i, true);
                if (i % 2 == 0) {
                    oTable.fnSetColumnVis(i, false);
                }
            }
        } else {
            for (var i = 1; i < lengthCols - 1; i++) {
                var bVis = oTable.fnSettings().aoColumns[i].bVisible;
                oTable.fnSetColumnVis(i, true);
            }
        }
        oTable.fnAdjustColumnSizing();
    },
*/
    all_trackers_checked: function() {
        var oTable = $('#trackers-datatable').dataTable();
        if ($('#all-trackers').prop('checked')) {
            $(oTable.fnGetNodes()).find(':checkbox').each(function() {
                $(this).prop('checked', true);
            });
        } else {
            $(oTable.fnGetNodes()).find(':checkbox').each(function() {
                $(this).prop('checked', false);
            });
        }
    },

    // Events
    // ---------------------------------------------------------------

    current_date: function(el) {
        var _id = $(el.currentTarget).attr('id');
        if (_id.substring(8) === 'startDate')
            $('#startDate').val(traky.dateFormatted(new Date()));
        else if (_id.substring(8) === 'endDate')
            $('#endDate').val(traky.dateFormatted(new Date()));
        else if (_id.substring(8) === 'published')
            $('#published').val(traky.dateFormatted(new Date()));
    },

    name_required: function() {
        $('.group-title').removeClass('has-error');
    },

    title_required: function() {
        $('.group-title').removeClass('has-error');
    }
});
