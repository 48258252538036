
$(document).on('traky_init', function() {

    traky.setHGroup = function(title, btns) {
        btns = btns || [];
        $('#page-title').text(title);
        $('title').text('Administración - ' + title + ' | Gestión de contraseñas');
        $('#header-btns').empty();
        _.each(btns, function(btn) {
            var el = '<a href="' + btn.link + '" class="btn btn-' + btn.type + '">' + btn.text + '</a>';
            $('#header-btns').append(el);
        });
    },

    traky.utils = {

        editorJSON: function($el) {
            if (typeof $el === 'string') {
                $el = document.getElementById($el);
            }
            return CodeMirror.fromTextArea($el, {
                mode: 'application/json',
                tabSize:    4,
                indentUnit:   4,
                matchBrackets: true,
                lineNumbers: true
            });
        },

        editor : function(el, data) {
            var opts = {
                container: el,
                textarea: null,
                clientSideStorage: false,
                localStorageName: 'epiceditor',
                useNativeFullscreen: false,
                parser: marked,
                file: {
                    name: 'epiceditor',
                    defaultContent: '',
                    autoSave: 100
                },
                theme: {
                    base: '/themes/base/epiceditor.css',
                    preview: '/themes/preview/preview-dark.css',
                    editor: '/themes/editor/epic-light.css'
                },
                button: {
                    preview: true,
                    fullscreen: true,
                    bar: "auto"
                },
                focusOnLoad: false,
                shortcut: {
                    modifier: 18,
                    fullscreen: 70,
                    preview: 80
                },
                string: {
                    togglePreview: 'Toggle Preview Mode',
                    toggleEdit: 'Toggle Edit Mode',
                    toggleFullscreen: 'Enter Fullscreen'
                },
                autogrow: false
            };
            epicEditor = new EpicEditor(opts);
            epicEditor.load();
            if (data) {
                epicEditor.editor.innerText = data;
            }

            return epicEditor;
        },

        ucfirst: function(string) {
            string = string.toLowerCase();
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        containsIgnoreCase: function(str1, str2) {
            str2 = String(str2);
            return str2.length <= str1.length ? str1.toLowerCase().indexOf(str2.toLowerCase()) > -1 : false;
        },

        isBlank : function(str) {
            return (/^\s*$/).test(str);
        },

        // convierte un string en un array de cadenas utilizando "separator"
        toTags: function(string, separator) {
            separator = separator || ',';
            var tags = [];
            var list = string.split(separator);

            for (var i=0, l=list.length; i<=l; i += 1) {
                if (list[i]) {
                    var tag = list[i].replace(/^\s+|\s+$/gm, '');
                    if (tag && tag !== '') {
                        tags.push(tag);
                    }
                }
            }

            return tags;
        },

        addSelectOption: function(value, label, selector, selected) {

            var options = {
                id: null,
                value: null,
                label: null,
                selected: false
            };

            if (typeof value === 'object') {
                options = _.assign(options, value);
            } else {
                options.id = selector || null;
                options.value = value || null;
                options.label = label || null;
                options.selected = selected || false;
            }

            var $option = $('<option>');
            if (options.value) $option.attr('value', options.value);
            if (options.label) $option.html(options.label);
            if (options.selected) $option.attr('selected', options.selected);
            $(selector).append($option);
        }
    };

    _.mixin({
        isEmail: function(email) {
            var re = /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i;
            return re.test(email);
        },

        isUrl: function(url) {
            var re = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
            return re.test(url);
        },

        // isNumber devuelve false si string numerico.
        isNumeric: function(number) {
            return !isNaN(number);
        }
    });
});


(function($){
    $.fn.disableSelection = function() {

        return this.attr('unselectable','on')
             .css({'-moz-user-select':'-moz-none',
                   '-moz-user-select':'none',
                   '-o-user-select':'none',
                   '-khtml-user-select':'none', /* you could also put this in a class */
                   '-webkit-user-select':'none',/* and add the CSS class here instead */
                   '-ms-user-select':'none',
                   'user-select':'none'
             }).bind('selectstart', function(){ return false; });

    };
})(jQuery);

(function($){
    $.fn.enableSelection = function() {

        this.attr('unselectable','off')
             .css({'-moz-user-select':'-moz-all',
                   '-moz-user-select':'all',
                   '-o-user-select':'all',
                   '-khtml-user-select':'all', /* you could also put this in a class */
                   '-webkit-user-select':'all',/* and add the CSS class here instead */
                   '-ms-user-select':'all',
                   'user-select':'all'
             }).bind('selectstart', function(){ return true; });

    };
})(jQuery);
