window.EventSponsor = Backbone.Model.extend({
    idAttribute: '_id',

    initialize: function() {
        this.on("invalid", function(model, error){
            tmw.validation(model, error);
        });
    },

    validate: function(attrs) {
        var errors = [];

        var validTypes = ['map', 'primary', 'type'];

        if (_.isEmpty(attrs.name)) {
            errors.push({
                name: 'sponsor-name',
                message: 'El título no debe estar vacío'
            });
        }

        if (!_.isNumeric(attrs.order)) {
            errors.push({
                name: 'sponsor-order',
                message: 'El orden debe ser un valor numérico'
            });
        }

        if (!_.isUrl(attrs.url) && attrs.url !== '#') {
            errors.push({
                name: 'sponsor-url',
                message: 'La url no es valida'
            });
        }

        return errors.length ? errors : false;
    }
});
